import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        className="section"
        style={{
          background: '#fff',
          alignItems: 'center',
          padding: size ? '2rem 0' : '3rem 0',
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-dark">
              <h3 className="text-400-cx text-justify">
                <br />
                <br />
                CX Summit would expand on the heightened awareness, organizational roadmaps and
                investments towards discussing strategies that create systematic customer delight
                across their fragmented journey. Over the past decade, the perspective has rapidly
                progressed from a narrow customer service focus to creating experiences that matter
                and ultimately add to the bottom line.
                <br />
                <br />
                The Summit would engage Customer experience Leadership in assessing their current
                state of affairs by benchmarking with the best-in-industry practices. CX Summit
                would holistically evaluate the larger picture by driving the dots between Employee
                experience and its impact on CX. A hypothesis is that engaged and motivated
                employees increase customer satisfaction and contribute to Net Promoter Score-NPS.
                <br />
                <br />
                The foundation and friction points associated with delivering successful Customer
                strategy based on customer data, technologies, processes and finally, people will be
                assessed holistically in light of the latest developments and changing social
                milieu. What differentiates the "winners" from others- what things could have been
                done differently, and what could perhaps be avoided through better employee training
                and engagement- these issues will be systematically dissected through case studies
                and timely solution deployment possibilities.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
