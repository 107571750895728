import React from 'react';
import { Container, Row, Col, Button, Card } from 'reactstrap';
import Slider from 'react-slick';

function TextGrid() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'p-10',
  };

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(' + require('assets/images/womentextgrid.png') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          position: 'relative',
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-women"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <h1 className="title mb-4 section-title text-center" style={{ color: '#624332' }}>
                <b>ABOUT THE EVENT</b>
              </h1>
              <h3 className="text-dark text-400">
                While the number of senior leadership positions occupied by women in the GCC is
                increasing by leaps and bounds, much more needs to be done.
                <br />
                <br />
                The promise is there, but what are the specific steps and policies that have to be
                incorporated to accelerate more women in regional boards?
                <br /> <br />
                An institutionalised approach that attempts to facilitate and support women
                leadership may be a way forward. Lessons can be learned from the way the governments
                nurtured women engagement in the public sector labor force, while also identifying
                and developing leaders. While quotas may be a solution to send a political message
                to society about the government’s vision for more diversity in leadership, they have
                to be supported by various other initiatives.
                <br />
                <br />
                The possibilities are endless, which is why Khaleej Times welcomes you to join
                “Middle-East Women Board of Directors Summit” on the 20th April in Dubai. This is
                not a gender-specific platform — rather it’s one that promises to facilitate network
                building, information sharing, and personal development of business leadership
                ecosystems.
                <br />
                <br />
                <b>
                  Middle-East Women Board of Directors Summit will provide the women the opportunity
                  to network with professionals from a variety of disciplines including
                  manufacturing, banking, pharma, engineering, media, sales, government and many
                  more.
                </b>
                <br />
                <br />
              </h3>
            </Col>
            <Col lg={8} className="text-center">
              <Button
                className="px-3 py-2 rounded-0"
                style={{ minWidth: '200px', background: '#624332' }}
                href="/register"
              >
                <p className="m-0 text-700" style={{ color: '#fff' }}>
                  REGISTER INTEREST TO ATTEND
                </p>
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt="Typing on laptop"
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="title mb-4 section-title text-center" style={{ color: '#624332' }}>
                <b>OBJECTIVES OF THE SUMMIT</b>
              </h1>
            </Col>
            <Col lg={6} className="text-center">
              <Card className="p-5" style={{ background: '#624332' }}>
                <h2 className="text-white text-700">
                  To increase the representation of women on boards of directors in local entities
                  across the public and private sectors.
                </h2>
              </Card>
            </Col>
            <Col lg={6} className="text-center">
              <Card className="p-5" style={{ background: '#624332' }}>
                <h2 className="text-white text-700 mb-4">
                  To increase women’s representation on boards of directors to 30% by 2022
                </h2>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt="Typing on laptop"
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.telr.com", image: "1.png" },
  // { link: "https://ae.visamiddleeast.com/en_AE", image: "2.jpg" },
];

export default TextGrid;
