import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

import Slider from "react-slick";

function News({ news }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title">
                <h1
                  className="pt-3 text-meta-g meta-title text-center"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  <b>GLIMPSES FROM THE 2022 EVENT</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={11} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {images.map((n, index) => (
                  <img
                    src={require(`assets/metaphotos/${n}`)}
                    width="100%"
                    alt="event"
                    className="px-1"
                  />
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ marginTop: "2rem" }}>
            <Col lg={12} className={"text-center"}>
              <Button
                href="/cx-evolve2022/news"
                className="btn"
                style={{ backgroundColor: "#000b2e" }}
                size="lg"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  "01.jpg",
  "02.jpg",
  "03.jpg",
  "04.jpg",
  "05.jpg",
  "06.jpg",
  "07.jpg",
  "08.jpg",
  "09.jpg",
  "010.jpg",
  "011.jpg",
  "012.jpg",
  "013.jpg",
  "014.jpg",
  "015.jpg",
  "016.jpg",
  "017.jpg",
  "018.jpg",
];
