import React from "react";
import Navbar from "componentsuaeIndia/Navbar.js";
import LandingPageHeader from "componentsuaeIndia/Headers/LandingPageHeader.js";
import Footer from "componentsuaeIndia/Footer.js";
import TextGrid from "componentsuaeIndia/TextGrid.js";
import TextGrid2 from "componentsuaeIndia/Textgrid2.js";
import TextGrid3 from "componentsuaeIndia/Textgrid3.js";
import Stats from "componentsuaeIndia/Stats.js";
import WhyAttend from "componentsuaeIndia/WhyAttend";
import News from "componentsuaeIndia/News";
import Photos from "componentsuaeIndia/Photos";
import SpeakerCard from "componentsuaeIndia/SpeakerCard";

import { news, speakers, sponsorsPage } from "./content";
import Sponsor from "componentsuaeIndia/Sponsor";
import Testimonials from "componentsuaeIndia/Testimonials";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id='outer-container'>
        <Navbar home={true} />
        <section id='home' />
        <LandingPageHeader />
        <main id='page-wrap'>
          <section id='about' />
          <Stats />
          <TextGrid />
          <TextGrid3 />

          <section id='speakers' />
          <SpeakerCard speakers={speakers} />
          <section id='agenda' />
          <WhyAttend />
          <Sponsor sponsors={sponsorsPage} />
          <TextGrid2 />
          <Testimonials />
          <News news={news} />
          <Photos/>
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
