import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className='text-main text-uppercase'>
                <b>About Khaleej Times Events</b>
              </h1>
              <h2 className='text-dark text-400'>
                Khaleej Times Events brings the power of our newspaper to live
                in-person and virtual experiences, featuring dynamic discussions
                with top CEOs, investors, entrepreneurs, policymakers, and
                innovators. With unique full-day, half-day, and salon programs
                led by renowned Khaleej Times anchors and reporters, our events
                deliver actionable insights, unmatched networking, and valuable
                sponsorship opportunities.
                <br />
                <br />
                <a
                  href='https://drive.google.com/uc?id=1dl24V9XpiYYeK4mgGqUvuc1mCTghS2zC&export=download'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-400'
                >
                  <b>Download the 2022 events calendar</b>
                </a>
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
