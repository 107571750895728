import React from "react";
import Navbar from "componentsMeta/Navbar.js";
import ProfilePageHeader from "componentsMeta/Headers/ProfilePageHeader.js";
import Register from "componentsMeta/Register";
import Footer from "componentsMeta/Footer.js";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="REGISTER NOW" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="REGISTER" />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
