// reactstrap components
import React from "react";

import Register from "componentsacc/Register";
import TextGrid from "componentsacc/TextGrid";
import Agenda from "componentsacc/Agenda";
// import Sponsor from "components/Sponsor";
// import SpeakerGrid from "components/SpeakerCard";
import NavbarDiv from "componentsacc/Navbar";

function LandingPageHeader() {
  return (
    <>
      <NavbarDiv />
      <img
        src={require("assets/images/bg.jpg")}
        width="100%"
        alt="bg"
        style={{ marginTop: "5rem" }}
      />
      <TextGrid />
      <section id="agenda" />
      <Agenda />
      <section id="register" />
      <Register type="REGISTER" />
    </>
  );
}

export default LandingPageHeader;
