import React from 'react';
import { Container, Button, Row, Col, Card, CardBody, TabContent, TabPane } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: '#624332' }}>
            <CardBody>
              <Container>
                <Row>
                  {/* <Col lg={2} className="align-self-center">
                    <h2 className="text-400 mb-0 text-white">
                      <b>{d.time}</b>
                    </h2>
                  </Col> */}
                  <Col lg={12} className="align-self-center text-white ">
                    <h2 className="text-600">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md={12}>
                <h1 className="title mb-1 section-title text-center text-uppercase" style={{ color: '#624332' }}>
                  <b>Key topics of discussion</b>
                </h1>
                <br />
              </Col>
              <Col md="12">
                <TabContent activeTab={'1'}>
                  <TabPane tabId="1">
                    <Container>
                      <Row>
                        <AgendaCard data={content1} />
                        <Col lg={12} className={'text-center'}>
                          <Button
                            href="/sponsor/download-brochure"
                            className="btn"
                            style={{ backgroundColor: '#624332' }}
                            size="md"
                          >
                            Request Full Agenda
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: '09.00',
    title: `OPENING KEYNOTE PRESENTATION - EMPOWERING WOMEN BY INCREASING THEIR REPRESENTATION ON THE BOARD
    OF DIRECTORS`,
    description: `
    Let’s hear from leaders in UAE on their commitment to support the national agenda of
ensuring fair representation of women on their Boards.
    `,
  },
  {
    time: '09.25',
    title: `PANEL DISCUSSION - 
    ACHIEVIENG GENDER BALANCED BOARDROOMS IN THE UAE`,
    description: `
    The UAE has made significant progress in the past decade in its vision to increase the
participation of women in both private and public sectors.<br/><br/>
According to the World Bank Blog Post, within the Middle East and North Africa, the UAE
has the highest level of women in the workforce — at 57.5 per cent! <br/><br/>
Points of discussion:<br/>
Let’s hear from the most successful women leaders in the UAE their takes on: 
<ul>
<li>Key challenges that women face in their onward journey to boardrooms, and their possible integration on boards. Facilitate discussions on possible solutions</li>
<li>The enormous support that they have received from family, mentors, role models,  business superiors and the UAE administration in a rapidly changing environment</li>
</ul>
    `,
  },
  {
    time: '09.30',
    title: 'NETWORKING BREAK',
  },
  {
    time: '09.50',
    title: 'PANEL DISCUSSION - NEED FOR THE RISE IN WOMEN WORKFORCE – UAE CONTEXT ',
    description: `
    UAE should understand every woman’s potential, and get a good return on investment
    made in women’s education and development in recent years.<br/><br/>
    Another contribution from adding more women is that they are well-educated and keen on
    lifelong learning, so they will contribute their up-to-date knowledge to decision-making
    and board monitoring functions.<br/><br/>
    IT IS:
    <ul>
    <li>To bring in better transformational leadership styles and better team players, who are less aggressive in the boardroom culture?</li>
<li>Do women agree with the quota intervention by the government? Or can they progress to the board based on merit? </li>
    </ul>

    `,
  },
  {
    time: '10.20',
    title: `KEYNOTE PRESENTATION - 
    IMPROVING CORPORATE GOVERNANCE IN UAE ORGANIZATIONS`,
    description: `
    Women should undergo formal corporate governance training in the duties and role of board
directors — with corporate governance developing rapidly in the UAE, in both private and
public sectors.<br/><br/>
After successful training, these women would be well-qualified for future directorships in
both public and private sectors.<br/><br/>
Can the government identify mechanisms with which, women can enter the talent pool, and
can it highlight and profile women who are ready for board appointments?
    `,
  },
  {
    time: '10.35',
    title: `INTERACTIVE DISCUSSION WITH THE AUDIENCE - 
    ON WHAT NEEDS TO BE DONE TO ACCELERATE THE WOMEN WORKFORCE:`,
    description: ` <ul>
    <li>Is a quota system necessarily the best approach?</li>
<li>Can mentoring and support programs for women be allowed for ‘safe spaces’ for women leaders to flourish?</li>
<li>Would joining and being active in professional bodies, trade associations and chambers of commerce, whether gender-specific or not, facilitate network building, information sharing, and personal development? – Examples </li>
    </ul>
    `,
  },
];

export default Agenda;
