import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset4.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="" style={{ color: "#0025c8", fontSize: "3rem" }}>
                <b>SYNOPSIS</b>
              </h1>
            </Col>
            <Col lg={6} className="align-self-center">
              <h2 className="" style={{ color: "#0025c8" }}>
                <b>CUSTOMER EXPERIENCE VS RISK MITIGATION: AN AVOIDABLE TRADE-OFF</b>
              </h2>
              <h3 className="text-dark text-400">
                Compared to its potential, the actual adoption of Cloud has been relatively
                lacklustre in the BFSI industry till now. A key factor that keeps financial
                institutions and their customers from embracing the Cloud is risk-aversion.
                Conventional banks, insurance and financial institutions might avoid experimenting
                with Cloud-led innovation and customer services.
                <br />
                <br />
                They choose to invest more in security measures, because of the high stakes
                involved. In such conditions, customer - centricity might take a backseat. On the
                other hand, challenger banks and new age fintech services are trying to capture the
                hidden market, constituting a diverse customer base, which prioritizes revolutionary
                customer experiences and absolute convenience, while performing financial
                transactions. Yet there is low acceptance due to lingering fears of cyber risks
                involved.
              </h3>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <img src={require("assets/images/security.png")} alt="security" width="100%" className="my-4"/>
            </Col>
            <Col lg={6} className="align-self-center">
              <h2 className="" style={{ color: "#0025c8" }}>
                <b>
                  HOWEVER, IS THIS TRADE - OFF BETWEEN CUSTOMER EXPERIENCE AND RISK MITIGATION TRULY
                  UNAVOIDABLE?
                </b>
              </h2>
              <h3 className="text-dark text-400">
                Not really. Indeed, it is possible to empower conventional banking institutions as
                well as neo banks with cutting edge cloud technologies, so that they can create new
                benchmarks incustomer experience and risk mitigation simultaneously.
                <br />
                <br />
                Join us in this interactive webinar to know more about the strategies to ensure
                Customer Experience and Risk Mitigation go hand in hand and how no trade-off is
                required whatsoever. We shall also discuss success stories of financial institutions
                that have aced the transformation race, risk-proof.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
