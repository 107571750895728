import React from "react";
import Navbar from "componentsuaeIndia/Navbar.js";
import ProfilePageHeader from "componentsuaeIndia/Headers/ProfilePageHeader.js";
import Register from "componentsuaeIndia/Register";
import Footer from "componentsuaeIndia/Footer.js";
import Sponsor from "componentsuaeIndia/Sponsor";
import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Sponsorship Enquiry" bgColor="#fff" />
      
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="SPONSOR" />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
