import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
function TextGrid() {
  let pageHeader = React.createRef();

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "6rem 0",
          backgroundImage: "url(" + require("assets/images/BackgroundCX.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-cx"></div>
        <Container fluid>
          <Row className="justify-content-left">
            <Col lg={1}></Col>
            <Col lg={5}>
              {/* <h1 className='section-title-cx my-4' style={{ color: "#051245" }}>
                <b>The Whole Experience is Greater than the Sum of each</b>
              </h1> */}

              <h1 className=" section-title-cx pt-3 text-cx-g" style={{ fontSize: "3rem" }}>
                <b>ABOUT THE EVENT</b>
              </h1>

              <h3 className="text-400-cx text-dark">
                In 2022 creating an incredible customer experience-CX journey is every
                organization's top priority. With rising competition, mapping and creating a wow
                experience throughout the customer's journey with the brand is the new normal. In
                the rapidly evolving global, always-connected and hyper-localized world, trust
                between consumers and brand is beyond sacred. As per the recent Salesforce survey-
                94 % of consumers are clear that a positive experience is as necessary as the actual
                product or service- as part of their decision-making towards repeat purchases.
                Furthermore, 74 % of them have switched brands due to shifting loyalties, and the
                concept of Loyalty is undergoing massive change. Where does this leave the brands?
                The writing on the wall is simple- consumers want hyper-personalized, unique,
                responsive, compassionate, environmentally and socially responsible brands that
                provide them with that element of all evasive "trust".
                <br />
                <br />
                The pandemic has accelerated the digital-first strategy and evolved end-users and
                service providers into an era of digital flux. Pandemic or no pandemic- one thing is
                clear there is no point of return from the hyper-digital world we have entered.
                Brands must deliver value beyond mere hyped-up personalization talks while
                safeguarding privacy and data integrity concerns. By anticipating rather than
                reacting, brands who are more proactive through data-driven predictive insights are
                better positioned to control customer experiences driving brand loyalty and creating
                the much-required competitive advantage.
                <br />
                <br />
                Consumers are willing to pay a premium for better experiences- up to 16 %, according
                to a PwC report- and are more likely to be loyal to the brand. Customer
                experience-CX has been improving over the past years- yet there is always more to do
                with rising consumer expectations, technology shifts, inflation, rising value
                consciousness, lesser disposable incomes, and greater demands that organizations are
                socially responsible. With UAE's Economy slated to grow at 4.6 % in 2022 versus 2.6
                % in 2021, raised consumption provides a natural opportunity for brands to capture
                the larger pie of market share. The key to unlocking this opportunity is simple: a
                smoother CX journey.
              </h3>
            </Col>
            <Col lg={6}>
              <div class="embed-responsive embed-responsive-4by3">
                <iframe
                  class="embed-responsive-item"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/N3_B_0NSJn4?autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.virenxia.com/", image: "main1.png" },
  // { link: "", image: "main2.jpg" },
  // { link: "https://allana.com/", image: "main3.jpg" },
  // { link: "https://www.tatacommunications.com/", image: "4.jpg" },
  // { link: "https://www.poly.com/ae/en", image: "5.jpg" },
  // { link: "https://bit.ly/2p0kYoJ", image: "6.png" },
  // { link: "https://www.teamviewer.com/tensor", image: "7.png" },
  // { link: "http://www.blueprism.com/", image: "8.png" },
];

export default TextGrid;
