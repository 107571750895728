import React from "react";

import Footer from "componentsuaeIndia/Footer.js";
import NavbarDiv from "componentsuaeIndia/Navbar";
import NewsAll from "componentsuaeIndia/NewsAll";
import { news } from "./content";
import ProfilePageHeader from "componentsuaeIndia/Headers/ProfilePageHeader";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <ProfilePageHeader
        title='Industry News'
        bgColor='#fff'
      />
      <NewsAll news={news} />

      <Footer />
    </>
  );
}

export default RegisterPage;
