export const speakers = [
  {
    name: "Abhay Kumar	",
    title: "Senior Vice President‑Digital Marketing & Social Media	",
    company: "DAMAC Properties Co. LLC,	UAE",
    image: "Abhay (1).jpg",
    text: [
      `
    Abhay is a transformative business leader with senior management experience globally. He
has managed large-scale businesses at Fortune 500 companies across financial services,
CPG, real estate and consumer tech, including at Johnson & Johnson, American Express and
Disney/Hotstar. <br/><br/>
Abhay brings significant experience managing functions including Marketing &
Communications, Innovation, Sales, Data Analytics and Digital commerce. He has delivered
wide-ranging success in setting strategy, gaining alignment, navigating highly regulated
environments, and setting up operating processes - while driving shareholder value. He has
been an early contributor to organisations building transformative strategies in AdTech,
fintech and proptech.<br/><br/>
Abhay brings high levels of execution experience with digital enablement of organisations:
Agile, Cloud, e-commerce (including P&L management), PLM/PIM, CMS, CRM, Clientele,
Digital Marketing (SEM, SEO, DMP), data systems (MDM, CDP) and Analytics. He is a regular
guest speaker to the prestigious IIMs, leading digital-and-technology business-
transformation-programs, and at international conferences on digital, consumer, luxury,
retail and technology-in-marketing.
    `,
    ],
  },

  //   {
  //     name: "Sanjeev Ramdas",
  //     title: "Executive Vice President Customer Service	",
  //     company: "Indigo,	India",
  //     image: "sanjeev ramdas.jpeg",
  //     text: [
  //       `
  //     Mr. Sanjeev Ramdas is an airline veteran and has headed airport operations for many years.
  // He has been with IndiGo ever since its inception in 2006.<br/><br/>
  // Mr. Sanjeev Ramdas holds a bachelor’s degree in commerce from University of Delhi and a
  // post graduate diploma in personnel management and industrial relations from YMCA
  // Institute of Management Studies.<br/><br/>
  // Mr. Ramdas worked for Cambata Aviation Private Limited from 1989 till 1994 as Duty Officer
  // and was in charge of aircraft handling, passenger services and charter flights. Prior to joining
  // IndiGo, he joined InterGlobe Air Transport Limited and was the Director of Airport Services
  // from 1994 till 2006 where he managed the Airport Operations for InterGlobe Air Transport
  // Limited’s airline partners and was responsible for Airport Operations, Customer Services,
  // Emergency Response Procedures and Safety.
  //     `,
  //     ],
  //   },
  {
    name: "Maya Nakhdjavani Rao",
    title: "Managing Director Customer Experience",
    company: "FedEx Express-  Middle East, Indian Subcontinent & Africa",
    image: "Maya PIC 2.jpg",
    text: [
      `Maya Nakhdjavani Rao is the Managing Director of Customer Experience at FedEx Express,
    covering the Middle East, Indian Subcontinent and Africa (MEISA) region. Maya manages the
    customer care operations and customer experience solutions for the region, with a team of
    approximately 970 people, located across the region. With the key responsibility of
    enhancing customer experience through market leading transformation and continuous
    improvement, Maya focuses on people, process, technology and innovation. Maya has over
    20 years of experience of which 17 years were with a fortune 100 financial institution,
    covering a number of products and business areas, including retail, commercial and
    corporate banking. Over the span of her career, Maya has held roles in scientific research,
    marketing and business development. Maya is passionate about driving a diverse and
    inclusive culture. In previous roles, she re-designed products and services in order to
    accommodate all aspects of diversity, and enable growth in less-served populations. She has
    also developed mentorship, education, and work placement programs to enable diversity to
    flourish within the organization. Maya is a keen supporter of education and has personally
    participated in programs to help aspiring students gain scholarships and funding for further
    education. Maya has a Bachelor of Science in Biological Sciences from Clemson University in
    South Carolina, and a Master of Business Administration from Duke University in North
    Carolina, USA. Maya is married with two sons. She enjoys reading and learning, and loves
    cooking, physical fitness, and performance arts.`,
    ],
  },
  {
    name: "Lianne Braganza-D’Sylva",
    title: "Chief Marketing Officer",
    company: "Cigna Insurance Middle East & Africa",
    image: "lianne.png",
    text: [
      `
    As Chief Marketing Officer for Cigna Insurance Middle East & Africa, Lianne is responsible
for reinforcing Cigna’s customer-centric approach and market growth through the
development and execution of strategic brand marketing, digital initiatives and the
Customer Experience program across the Middle East and Africa.<br/><br/>
Based in Dubai, Lianne is an integral part of Cigna’s Executive team and has played a
pivotal role in setting up the marketing function which has elevated the Cigna brand and
maintained it as the company went through an acquisition.<br/><br/>
Lianne is a strong advocate of Cigna’s core mission to actively contribute to the sustained
well-being of individuals and societies around the world. To this she adds a personal
commitment to furthering the cause of diversity in the workplace. She sponsors the
Women Influencing Networking chapter in the MEA region. The resource group builds
support networks, provides mentoring, and finds resources to help women advance in the
workplace.
    `,
    ],
  },
  {
    name: "Zafeer Shamsudeen",
    title: "Director of Sales and Marketing",
    company: "Al Rawabi,	UAE",
    image: "Zafeer SHAMSUDEEN.jpeg",
    text: [
      `Zafeer Shamsudeen has the ability and perspective needed to be successful at the senior level of any organization. He uses a model of supportive and participatory leadership to promote the concepts of team building and empowerment within a company and functional teams. During his career, he has gained Experience of introducing new products launches, project Management, restructuring operations while growing sales, and leading cross-functional teams across both domestic and gulf markets. Vast industry experience includes consumer goods, manufacturing, and banking services products in GCC Markets & Asian Markets. Proven ability to manage large volumes of business and product development efforts with full life cycle oversight various industries across the board and now knows fields that are critical to an organizations success. His key strengths lie in the ability to maximize the profitability of the company, launch inventive business strategies and inspire individuals with whom he works. Skilled in all aspects of brand management, sales and distribution channels, and continuous process improvement. Demonstrated capability to develop relationships with diverse groups across multi-cultural settings while capturing substantial market share in new regions.`,
    ],
  },
  {
    name: "Prateek Kumar",
    title: "Senior Vice President – Dusit International",
    company: "General Manager – Dusit Thani Dubai",
    image: "Prateek Kumar 2.JPG",
    text: [
      "Mr Prateek first joined Dusit 14 years ago, in 2008, as General Manager of Dusit Thani Manila. In January 2013, he became General Manager of Dusit Thani Dubai. Two years later, he was promoted to Area General Manager – UAE, followed in 2017 by his most recent role: Regional Vice President – EMEA.",

      "During his tenure, Mr Kumar has spearheaded the successful opening of several new Dusit Hotels and Resorts in EMEA while overseeing multiple operating and preopening properties.",
      "In his new role as Senior Vice President – Operations, he will be responsible for enforcing brand quality standards, enhancing customer satisfaction, and delivering optimum financial returns at each property under his supervision. He will also continue to drive business development in EMEA and India, which have strong potential for Dusit’s brands. His working base will remain to be Dubai, where he will continue to serve as General Manager of Dusit Thani Dubai.",

      "Mr Kumar holds a bachelor’s degree in hotel management from Griffith University, Australia. During his career, he has worked in senior management positions for the former Starwood Hotels and Resorts and Renaissance/Marriott Hotels in Australia. Prior to joining Dusit, he worked for Raffles Hotels and Resorts and was responsible for the successful preopening of Ascott Raffles Place, Singapore.",

      "Testament to Mr Prateek’s expertise and talent, he was recently featured on Hotelier Middle East’s renowned Executive Power List 2022, which features the 50 most influential hospitality leaders in the MENA region. It is the fourth time he has made the prestigious list, having also appeared in 2018, 2019, and 2020.",

      "In recognition of his innovative approach to brand building and customer management in the Gulf region, he was also named one of GCC’s Best General Managers (Hospitality) at the World Leadership Congress and Awards in 2018.",
    ],
  },

  {
    name: "Bernadette Geagea​",
    title: "Head Of Customer Experience Operations",
    company: "Chalhoub Group,	UAE",
    image: "BernadetteG.jpeg",
    text: [
      `Bernadette is a people’s-person striving to develop others to excel and a firm believer and
      practitioner of effective cross-cultural communication, anchored with ethical principles in
      any decision-making.<br/><br/>
      Over 20 years of comprehensive and in-depth professional experience in mono and hybrid
      customer experience, omnichannel, digital marketing, Ecommerce, operational logistics,
      retail, and e-retail. Driving high-performing team management, enhancing customer
      experience, and manifesting growth and profit margins.<br/><br/>
      She is described as the “Chief Energy Officer” by her colleagues`,
    ],
  },
  {
    name: "Hany Mokhtar",
    title: "Experience Senior Advisor (CX and Digital Trans. World class expert-The CX Master )",
    company: "Mobily, KSA",
    image: "HanyMokhtar.jpeg",
    text: [``],
  },
  {
    name: "Jonathan Steingiesser",
    title: "Head Of Product Design",
    company: "Omnipresent,	UAE",
    image: "Jonathan.jpeg",
    text: [
      `Jonathan Steingiesser is based in Dubai as the Head of Product Design at Omnipresent, a fully
    remote start-up, where he is building a product design team and maturing the practice of product
    and design in this fast-growing start-up. With over 18 years of product design experience in start-
    ups, retail, tech, non-profit, mining, government and education sectors, Jonathan has fostered a
    passion for design innovation and continuous improvement through operationalising design and
    research practices.    Jonathan has advised start-ups all over the Middle East and in Australia.
    He is the founder of the Human Centre Design UAE Meetup and was the co-organiser of Service
    Design Perth and UX Melbourne Meetups in Australia. He also supports and advises the UAE-IL
    Tech Zone and the DANA Start-up Accelerator as a Strategic Advisor.`,
    ],
  },
  // {
  //   name: "Dr. Hassan Somili",
  //   title: "Co-founder ",
  //   company: "Customer Experience Training Center,	KSA",
  //   image: "Hassan-Somili.png",
  //   text: [
  //     `Dr Hassan got his PhD  (Social Media Marketing)  from Sheffield Hallam University, the UK, in 2018,
  //   and has a  MA in Marketing Communication from the University of Leeds, Leeds Business School.
  //   He has over 20 years of academic and consultancy experience and has been a lead consultant in
  //   many of the authorities and sectors of government, private and non–profit. Now he is working as an
  //   academic and co-founder of a customer experience training centre. Dr Hassan has participated in
  //   customer experience development projects, including UX and PX. Moreover, he is interested in
  //   developing student experience and non-profit-related experience.`,
  //   ],
  // },
  {
    name: "Despina Kokkaraki",
    title: "",
    company: "Customer Experience & Growth Leader ",
    image: "despina_kokkaraki.jpg",
    text: [
      `
    Despina Kokkaraki is a Customer Experience & Digital expert who, for almost two decades, has
delivered effective and profitable customer-centric strategies and digital transformation programs
across different industries to some of the biggest names on the global marketplace, from the
inside.<br/><br/>
Passionate for all things Customer & Digital, she is a strategic growth leader and customer
representative within the organizations she has worked and consulted in. She has been guiding
leadership teams and executive boards from within with Customer-centric insights, devising
compelling Customer Experiences while developing talent, culture and capabilities in order to build
sustainable growth & Customer loyalty with high performing and diverse teams of CX, Brand &
Precision Marketing, CRM, Digital Product & Data subject matter experts.<br/><br/>
She has worked as an agent of Change in Digital, Omnichannel Transformation and Marketing
automation as well as Customer Data programs driving effectiveness and customer-centred
innovation that drives growth.
    `,
    ],
  },
  {
    name: "Kartik Bhatt",
    title: "Chief Operating Officer- GCC and South East Asia",
    company: "Sharaf Retail ",
    image: "KartikBhatt.webp",
    text: [
      `P&L LEADERSHIP || OMNICHANNEL RETAIL || STRATEGY II DIGITAL TRANSFORMATION || ADVISOR II START-UP INCUBATOR
    ADVENTURE HQ II FOREVER 21 II COTTON ON II TYPO II
    <br/><br/>
    * Successful Omnichannel Business Leader ( COO ) with 18 + years of PNL Management, Operational Leadership, Retail Strategy & Digital Transformation experience across Ecommerce & Brick N Mortar Retail (Adventure, Fashion & Duty-Free Retail) across GCC, South East Asia & India.
    <br/><br/>
    * Entrepreneurial & Start-up experience setting up Fashionara.com in a venture-funded fashion & lifestyle E-Commerce start-up in India with 200+ brands, catering to around 1.3 million shoppers annually.
    <br/><br/>
    * Building Adventure & Fashion retail's largest marketplace across GCC with more than 100K products across 5 countries, reaching out to more than 60 million digital shoppers. ( Verve & Adventure HQ)
    <br/><br/>
    * Digital Transformation of Sharaf Retail ( Adventure HQ, Forever 21, Cotton ON, Typo)
    <br/><br/>
    * Global Business Development & Expansion experience in setting up & operating Travel & Duty-Free business across India, UAE, Malaysia, Indonesia, Poland, UK, Brazil, Caribbean, Oman, Bahrain & Hongkong.
    <br/><br/>
    * Retail Coach & advisor to mid-size retailers supporting Digital Transformation, Category, Inventory & Operational efficiency optimisation using Retail Analytics & Data Sciences & Overall Business Strategy.
    <br/><br/>
    * An avid blogger and keynote speaker.`,
    ],
  },
  {
    name: "Rania Nerhal",
    title: "Chief Client Experience & Conduct Officer",
    company: "Mashreq Bank, UAE",
    image: "Rania.jpg",
    text: [
      `
    Rania Nerhal is the Chief Client Experience & Conduct Officer of Mashreq. She heads the bank’s Client
Experience & Conduct Group (CXCG), the group is responsible for enhancing the bank's client experience
strategy. Her primary objective at CXCG is to ensure that business continues to be conducted efficiently,
ethically and effectively, and all policies are clearly and regularly communicated to all clients. <br/><br/>
Rania joined Mashreq in February 2018 as Head of Public Sector, Healthcare, Education & Energy. Under
her leadership, relevant sector teams established different thought leadership platforms including the
MEED Energy Club as well as the Frost & Sullivan Healthcare Forum.<br/><br/>
Rania brings more than 25 years of experience in client relationship management in the banking
industry. Prior to joining Mashreq, she held executive management roles such as Head of Client
Corporate Coverage and Head of Large Corporates & Public Sector at Egyptian American Bank,
Commercial Bank of Dubai, and Al Ahli Bank of Kuwait.<br/><br/>
Most recently Rania worked with HSBC, where in addition to her role as a Senior Corporate Banker, she
was the Head of Corporate Banking, Dubai, where she led corporate banking coverage teams in charge
of a large portfolio of diversified industries and segments.<br/><br/>
Rania holds a Bachelor’s degree in Economics from Faculty of Commerce and Foreign Trade, Cairo,
Egypt.`,
    ],
  },
  {
    name: "Linoy Kidd",
    title: "CIO, Markets & Securities Services MENAT",
    company: "HSBC Bank, UAE",
    image: "LinoyKiddHSBC.jpeg",
    text: [
      `Linoy is the MSS CIO of Markets Security Services MENAT, based in Dubai. She has worked for HSBC for 16 years and worked in Mexico, London, Hong Kong and China building teams.  On the side, she builds schools– she has built 7 so far in Malawi, Senegal, Haiti, Mexico and Nicaragua. `,
    ],
  },
  //   {
  //     name: "Aly Ajani",
  //     title: "Head of Corporate Bank",
  //     company: "Citibank, UAE",
  //     image: "Aly Ajani photo.jpg",
  //     text: [
  //       `
  //     Aly Ajani is currently the Corporate Bank Head for Citi in
  // the UAE. He is an experienced banker, with Corporate
  // Banking, Corporate Finance and Mergers & Acquisitions
  // capabilities in the UAE banking sector.<br/><br/>

  // Responsible for the Corporate Banking business in the UAE, Aly provides client
  // coverage and drives business growth with a track record of delivering profitable
  // business growth by building strong client relationships. He has extensive
  // experience in portfolio, risk and relationship management with strong
  // credentials of delivering seamless execution of transactions.<br/><br/>
  // Aly joined Citi in 2002 in Pakistan. He moved to Dubai in 2010 and has been
  // instrumental in developing Citi UAE’s client base and positioning Citi as a top
  // international bank. He previously worked in Qatar and Pakistan where he was
  // responsible for managing corporate and public sector relationships.<br/><br/>
  // Since 2016, Aly has been heading the Dubai Corporate Bank business and has
  // been involved in various capacities including leading the origination of some
  // landmark deals with the most sophisticated clients in Dubai.<br/><br/>
  // Aly holds an MBA from the Institute of Business Administration in Karachi. He is
  // fluent English and Urdu. Aly is married with two kids, is an avid golfer and
  // volunteers with Injaz Al-Arab in the UAE.`,
  //     ],
  //   },
  {
    name: "Lina Yahya",
    title: "Associate Director - Service Excellence Unit",
    company: "RAK Bank, UAE",
    image: "Lina-Yahya.png",
    text: [``],
  },
  {
    name: "Ali Shabdar",
    title: "Regional Director, MEA",
    company: "Zoho , UAE",
    image: "compress.Ali Shabdar.JPG",
    text: [
      `Ali Shabdar is the Regional Director of the Middle East and Africa at Zoho Corp. A business technologist, advisor, and published author, Ali advocates for practical and profitable digital transformation, with added focus on customer and employee experience as well as information management and privacy.<br/>
      Ali also serves as the Chair of the Community Outreach at MENA Cloud Alliance, an organization with a focus on strengthening local cloud markets, leveraging a unique community comprised of major players within the regional tech ecosystem.<br/>
      Passionate about people, process, education, and business strategy, Ali presents ideas about the best uses of technology in the service of the business and society with a triple-bottom approach.<br/>
      
      Twitter: @shabdar`,
    ],
  },
  {
    name: "Hithem Ibrahim",
    title: "Senior Director of the Global Operations",
    company: "Majorel, Egypt",
    image: "Hithem.JPG",
    text: [
      `Hithem Ibrahim is an experienced leader in the
      CX Industry, with a solid history in structuring
      high-performing CRM, strategic planning,
      budgeting and execution of CX with more than
      21+ Year experience across multiple destinations
      including Egypt, New Zealand, Qatar, Italy, India
      and Ireland.<br/><br/>
      He has a concrete capability in managing and
      leading continuous improvement of the overall
      customer experience throughout his journey
      with a tactical emphasis on customer care,
      quality management, work force planning and
      optimization, coaching and training.
      <br/><br/>
      Currently, Hithem is the Senior Director of the Global Operations for Majorel Egypt, handling the Global
      EMEA accounts.<br/><br/>
      Throughout his experience, he focused on commitment to Operational excellence demonstrated by his
      continuous staff development engagement, knowledge sharing and contributing actively to achieving
      the company’s overall operational targets and business expansions. Hithem continues to execute the
      vision of operational excellence ensuring that Majorel Egypt meets customer and organization needs by
      leading a team of more than +2500 FTEs across 4 Sites in Cairo, Alexandria and BeniSuef,
      Hithem’ s journey includes more than 15+ years in the telecommunication industry where he has shown
      measurable accomplishments contributing to profitable call centers results with well-trained and
      motivated support teams, driven to ensure customer satisfaction and loyalty through all deliverables.`,
    ],
  },
  {
    name: "Ankesh Agarwal",
    title: "Group CX Senior Manager - CX Strategy, Center of Excellence",
    company: "Majid Al Futtaim, UAE",
    image: "Ankesh Agarwal 2021.jpg",
    text: [
      `
    Ankesh is a seasoned CX practitioner with a passion for creating memorable customer experiences via
consumer understanding, digital transformation, and organization alignment.<br/><br/>
He has a rich experience of 16 years in successfully managing CX advisory, CRM, E-comm, and Insights
engagements across the Middle East and Indian subcontinent. Currently with Majid Al Futtaim managing their
CX programs and supporting the Group CX strategy. Prior to this, he was with Al-Futtaim, a large diversified
business group, setting up their VoC program, and Kantar, a leading marketing consulting, deploying/
activating large-scale CX programs for a diversified set of clients. He also worked with an agile software
product start-up, Capillary Technologies, ensuring customer success and deploying CRM and E-Comm solutions
for enterprise clients.<br/><br/>
He brings a holistic perspective on CX consulting, client-side understanding, entrepreneurial zeal,
and stakeholder management to his Customer Experience engagements.
    `,
    ],
  },

  {
    name: "Savio Tovar Dias",
    title: "Senior Director – Sales Engineering",
    company: "Avaya International",
    image: "Savio Tovar Dias (2) sm.jpg",
    text: [
      `Savio Tovar Dias heads the Sales Engineering organization for Avaya International, which
      encompasses Europe, the Middle East &amp; Africa, and the Asia-Pacific market. He helps to define
      the company’s technology strategy to drive demand across a diverse market landscape, with a
      strong focus on customers’ consumption of technology. Savio is committed to helping
      companies differentiate themselves in a highly competitive and rapidly changing technology
      and market landscape.<br/><br/>
      Savio’s focus includes aligning technical resources to deliver business solutions that address
      technology evolution, competitive dynamics and market demands to drive innovation within
      Avaya’s customer base. With extensive expertise in Unified Communications &amp; Collaboration,
      Video, Contact Centers, Data Networks, and SOA-based applications, Savio is a leading authority
      on the global communications market. And in recent years, he has worked to define Avaya’s
      strategies on integrating artificial intelligence (AI) into Customer Service processes.<br/><br/>
      A communications industry veteran with 22 years’ experience, Savio has filled several senior
      roles, including technical engineering, R&amp;D and consultancy, with AT&amp;T, Lucent Technologies
      and Avaya. He holds a Bachelor&#39;s Degree In Electrical &amp; Electronics and a Master&#39;s Degree in
      Communications Engineering.`,
    ],
  },
  {
    name: "Marc Karschies",
    title: "Managing Partner",
    company: "KCA Consultants, UAE",
    image: "Marc Karschies 300x300.jpg",
    text: [
      `      Marc Karschies (CCXP) is the Managing Partner at KCA Consultants, an international CX and Service Strategy consultancy firm based in Dubai. 
Marc is considered one of the leading experts on Customer Experience (CX) Strategy and Service Quality Management in the MENA region. His cross-industry advisory includes CX and Service Strategy, Government Excellence Programs (e.g. Dubai Model, Services 360, DGEP, EFQM), Integrated KPI & Measurement Systems, and Channel Optimization. `,
      `He holds the CCXP Accreditation and is the only CXPA Recognized Training Provider (RTP) based in the UAE. He is a frequent expert keynote speaker and Award Judge on Customer Experience and Service Quality Strategy. Marc is also the co-author of the Amazon bestselling book series "Customer Experience" and "Remote Working", and the winner of the 2018 ASQ MEA “Quality Professional of the Year” Award in recognition of his contributions and achievements in Quality Management.`,
    ],
  },
  // {
  //   name: "Hetarth Patel",
  //   title: "CX Transformation Director-ECEMEA",
  //   company: "Oracle",
  //   image: "IMG_8639.jpg",
  //   text: [
  //     `

  //     Hetarth Patel is heading a Strategy and Transformation Program centred around Customer Experience at Oracle for Eastern Central Europe and the Middle East, and Africa. He comes with more than 20 years of experience in helping organizations to accelerate their digital transformation journey based on customer 360 and artificial intelligence. He has helped leading organizations across the globe to maximize the value of their customer data and deliver comprehensive customer value management programs generating consistent incremental revenues up to 6-8%.

  //     Prior to Oracle, Hetarth was a Global Senior Vice President at Flytxt BV, where he has helped more than 100 visionary enterprises realise the full potential of scientific marketing technologies and improve their customer lifetime value. `,
  //   ],
  // },
  {
    name: "Maarten van den Nieuwenhuijsen",
    title: "Vice President Openings & Guest Quality India, Middle East, Africa & Turkey",
    company: "Accor",
    image: "Maarten Vanden Nieuwenhuijsen_0058.jpg",
    text: [
      ` Maarten van den Nieuwenhuijsen, a Dutch national, has worked for over 30 years in the Hotel Industry. During his career, he worked for various International Hotel organizations such as IHG, Radisson, Mövenpick and Accor and lived in several countries. He is a seasoned operational hotelier and leader, having worked as a Hotel General Manager, Regional Director, Vice President of Operations and VP of Brand Compliance. He currently is Vice President of Hotel Openings and Guest Quality for Accor India Middle East, Africa and Turkey-based in Dubai and responsible for around 25 Hotel Openings per annum and the Guest Quality measurement of over 430 hotels.

    `,
    ],
  },
  {
    name: "Rob Collier",
    title: "Managing Director, Future Openings MEA",
    company: "Radisson Hotel Group",
    image: "IMG_2884_1.JPG",
    text: [
      `A British national, Rob has been with Radisson Hotel Group for over 14 years. He began his progressive career in the UK  and moved to Dubai in 2012, as a General Manager at the Radisson Blu Hotel, Dubai Marina; and later went on to hold leadership positions in the Area Support Office in Dubai. Collier was recently promoted to Managing Director, Future Openings MEA. In his role he is responsible for directly liaising with the project managers, owners, and key stakeholders to drive the pre-opening process, directing timelines for completion and handover of all the hotel openings in the region.`,
    ],
  },
  {
    name: "Karthik Chandra Akula",
    title: "Regional Head Middle East",
    company: "Freshworks",
    image: "Karthik Chandra Akula (2).jpg",
    text: [
      `Karthik is a successful sales leader at Freshworks. In his current role as the Regional Head for the Middle East, he actively drives Freshworks' business growth across geographies and industry verticals. He loves creating business value by empowering Freshworks' customers to delight their Employees & Customers in a fast and easy manner.`,
    ],
  },
  {
    name: "Matthew Robinson",
    title: "VP Marketing",
    company: "Contentsquare",
    image: "headshot-matt.jpeg",
    text: [
      `
      Matthew is the Vice President of Marketing at Experience Analytics leaders Contentsquare. In this role, he looks after 360-degree marketing initiatives, advocating for More Human Analytics and occasionally writing about himself in the third person. In his spare time, Matthew continues his quest to find the world's best pizza.`,
    ],
  },
  {
    name: "Kowshik Bhattacharjee",
    title: "Head of Service Excellence",
    company: "Medcare Hospitals & Medical Centres",
    image: "_DSF3308.JPG",
    text: [
      `Kowshik Bhattacharjee comes with 23 years of rich experience, encompassing Hospitality/ BPO &amp;
    Healthcare Sector. He has been associated with one of the finest brands all like Taj Hotels/ Marriott/ Le
    Meridien/ ITC Hotels/ Dell Computers/ Apollo Hospitals &amp; currently with Medcare Hospitals and Medical
    Centers, as the Head of Patient Experience, which is one of the premium brands of DM Aster Healthcare
    group in Dubai.<br/><br/>
    During his tenure with Apollo Hospitals, he institutionalized the concept of Service Excellence in
    Healthcare and brought about significant best practices from other Service Industries. Few of his
    projects like Voice of Customer Methodology/ Dial 30/ Tender Loving Care/ AAPIE has been awarded in
    both domestic and international platforms. He is a well renowned name in the field of Customer
    Experience in India and has been conferred with the prestigious “Bharat Gaurav Award” in 2017 by
    Central Ministry.<br/><br/>
    In his new assignment, he is the Head of Service Excellence and is responsible for creating the Customer
    Experience framework for Medcare Hospitals and Medical Centres and playing a key role in
    Transformation. In a span of 2 years, he has been able to accelerate the Service Excellence
    Transformation in Medcare. Under his leadership Medcare has been conferred with Gulf Customer
    Experience Awards for Best Patient Experience &amp; Best CX Team of the Year in Feb 2022.`,
    ],
  },

  {
    name: "Craig Lee",
    title: "Independent Advisor & Partner",
    company: "Kinetic Consulting Services",
    image: "Craig_formal.jpg",
    text: [
      `Craig Lee helps organisations improve business results by challenging how they think, grow and transform.<br/><br/>
      He helps business leaders join the dots between their customers, their brands and their employees to deliver experiences that matter - profitably and sustainably.
      <br/><br/>He focuses on relationship building and helping shape inspiring customer and employee experiences in organisations with diverse cultures and languages.

      <br/><br/>He advises C-suite leaders and coaches globally on customer experience, employee engagement, integrated marketing and bringing brands to life.
      
      <br/><br/> Craig has worked in the financial services, telecoms, luxury and premium retail, technology, education, hospitality, healthcare, aviation and travel sectors.
      
      <br/><br/> He is an international keynote speaker on customer experience, business transformation, and growth.
      
      <br/><br/> Craig is an independent advisor, a Partner at Kinetic Consulting Services, and previously held the position of Customer Experience and Brand at Emirates.
      
      <br/><br/>He is based in Dubai and holds an Industrial Psychology degree from the University of Stellenbosch, South Africa.`,
    ],
  },
  {
    name: "Renata Liuzzi",
    title: "CEO & Founder",
    company: "Axis Partners",
    image: "Renata.jpg",
    text: [
      `
    A global business-transformation strategist, Renata has a proven track record with blue chip
consultancies. 17+ years of experience in Business Strategy, Customer Experience, Innovation
and Digital Transformation working for numerous Fortune 500 companies.<br/><br/>
International MBA from IE Business School, Mechanical Engineer (with masters in Motorsports),
Post-Diploma in Design-Thinking, Innovation and Strategy (MIT, Columbia). With certifications
in Lean Six Sigma, Artificial Intelligence (MIT) and Operational Efficiency (APICS).
Renata has successfully lead the delivery of complex transformation programmes, working with
teams spread across more than 15 countries and doing business with more than 20 countries.
She has led Customer Experience / Digital departments companies like Emaar Group, Dubai
holding, and Meraas Holding.<br/><br/>
She founded Axis Partners to respond to Strategy, CX and innovation challenges, a critical
component of business survival and evolution post-pandemic. Engaged as a strategic advisor to
major sovereign investment organisations, major retail and real estate groups in MENA and
Europe, Renata successfully delivers transformation projects, turning crisis to opportunities.
She is a thought leader and for that reason, she has been appointed a visiting Lecturer at the IE
Business School, where she teaches Strategy, CX and Design thinking to International MBA
Students. She has been also appointed as a Mentor for top start-ups coming from the best
innovation Lab in Europe (IE-VentureLab).<br/><br/>
Renata was awarded in 2022 as the second most influential CX/Innovation practitioner
worldwide by the CX Magazine and featured as one of the top CX/innovation thought-leaders in
2021.<br/><br/>
She was recently featured in the front page of the Retail Magazine, where she discusses the
future of CX with the impact of NFT, Metaverse and Blockchain.
    `,
    ],
  },
  {
    name: "Jeevan D'Mello",
    title: "Chief Executive Officer",
    company: "Zenesis Corporation",
    image: "jeevandmello.jpeg",
    text: [
      `
      Professionally trained as an Architect, Jeevan has had a wide-ranging international career in Design, Project Management, Customer Experience, Community Management and International Relations in India, Hong Kong, Bahrain and UAE.
      <br/><br/>A prolific author, he ran a regular column in a premier publication in Hong Kong, following which in 1995, he co-authored and published a book and an international magazine.
      <br/><br/>After a brief stint in HSBC, Jeevan joined Emaar to set up the company’s customer-facing divisions, subsequently handing over the first freehold property in Dubai and setting up its property handover division that oversaw the handing over of over 22,000 freehold units. A RERA-authorised Lecturer at the Dubai Real Estate Institute, he has been recognised as the 'Father of Community Management in the Middle East. In 2008 he became the first Certified Manager of Community Associations (CMCA) in the region and later the first Association Management Specialist (AMS) certified by the US-based Community Association Institute for the region. In 2012 he became one of the first international Professional Community Association Managers (PCAM) and the first international Large Scale Manager (LSM). In 2011 he received the Institute's 'Rising Star Award' and in 2014, the prestigious 'President's Award' in the USA.
      <br/><br/>He spent 15 years looking after Emaar's customers and managed 5 master planned communities with a portfolio of over 35,000 units, including Burj Khalifa. A regular on the international speaking circuit, he has spoken in conferences and workshops worldwide.
      <br/><br/>Jeevan served a 6-year term on the US-based Community Managers International Certification Board. In 2019 he was appointed as the first international professional to the Board of US-based Community Associations Institute. He was also the inaugural President of the CAI – the Middle East and a current Board Member. Jeevan is the co-author of the book "Los Mejores Administradores de Edificios del Mundo" globally released in 2019.
      <br/><br/>A dedicated Rotarian, he was elected to the Board of the Rotary Club in Dubai in 2020 and later was appointed as the UAE Country Trainer for Rotary International.
      
       
    `,
    ],
  },
];

export const pastspeakers = [];

const sponsors = [
  {
    name: "AVAYA ",
    pageLink: "AVAYA ",
    type: "TECHNOLOGY PARTNER",
    image: "avaya.jpg",
    text: [
      `Avaya is shaping what's next for the future of work, with innovation and partnerships that that redefine the customer and employee experience. Our Avaya OneCloud™ experience as a service platform and multi-cloud application ecosystem power personalized, intelligent, and effortless experiences to help you achieve your strategic ambitions and desired outcomes.`,
    ],
    link: "https://www.avaya.com/en/",
    size: "100%",
    grid: 4,
  },
  {
    name: "Freshworks ",
    pageLink: "Freshworks ",
    type: "STRATEGIC SPONSOR",
    image: "freshworks.jpg",
    text: [
      ` Freshworks Inc., (NASDAQ: FRSH) makes business software people love to use. Purpose-built for IT, customer support, sales and marketing teams, our products empower the people who power business. Freshworks is fast to onboard, priced affordably, built to delight, yet powerful enough to deliver critical business outcomes. Headquartered in San Mateo, California, Freshworks operates around the world to serve more than 56,000 customers including Bridgestone, Chargebee, DeliveryHero, ITV, Klarna, Multichoice, OfficeMax, TaylorMade and Vice Media.`,
    ],
    link: "http://www.freshworks.com/",
    size: "100%",
    grid: 4,
  },

  {
    name: "ORACLE ",
    pageLink: "ORACLE ",
    type: "GOLD SPONSOR",
    image: "oracle.jpg",
    text: [``],
    link: "https://www.oracle.com/",
    size: "100%",
    grid: 4,
  },
  {
    name: "Content Square ",
    pageLink: "Content Square  ",
    type: "GOLD SPONSOR",
    image: "contentsquare.jpg",
    text: [``],
    link: "https://contentsquare.com/",
    size: "100%",
    grid: 4,
  },
  {
    name: "MAJOREL ",
    pageLink: "MAJOREL ",
    type: "GOLD SPONSOR",
    image: "majorel.jpg",
    text: [
      `
    Majorel Egypt is a leading provider of next-generation end-to-end customer experience (CX) solutions with over 8,000 professionals providing unparalleled Customer Experience (CX) Solutions in multiple languages for clients in #EMEA.  <br/>
    <br/>
Our customer interaction solutions are centered on augmenting human talent with advanced proprietary technology tools, automation – like RPA and bots – and data insight.<br/><br/>
We enhance this approach with deep vertical, cultural, and CX domain expertise, developed from partnering with top brands for more than 20 years.<br/><br/>
Solutions: <br/>
BPO | Outsourcing | Omni-Channel | RPA | Customer Experience Management (CXM). 
    `,
    ],
    link: "https://www.majorel.com",
    size: "100%",
    grid: 4,
  },

  {
    name: "ZOHO ",
    pageLink: "ZOHO ",
    type: "SILVER SPONSOR",
    image: "zoho.jpg",
    text: [
      `
    With 50+ apps in nearly every major business category, including sales, marketing, customer support, accounting and back-office operations, and an array of productivity and collaboration tools, Zoho Corporation is one of the world’s most prolific software companies.

 <br/>
 <br/>
Zoho is privately held and profitable with more than 10,000 employees. It is headquartered in Austin, Texas, with international headquarters in Chennai, India. Additional offices are in the United States, India, Japan, China, Singapore, Mexico, Australia, the Netherlands, and the United Arab Emirates.
<br/>
<br/>

Zoho respects user privacy and does not have an ad-revenue model in any part of its business, including its free products. More than 75 million users around the world, across hundreds of thousands of companies, rely on Zoho everyday to run their businesses, including Zoho itself.
    `,
    ],
    link: "https://www.zoho.com/",
    size: "100%",
    grid: 4,
  },
];
const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    title: "CX Evolve 2022 conference: Firms told to use AI to enhance customer experience, not burden them with issues",
    link: "https://www.khaleejtimes.com/uae/cx-evolve-2022-conference-firms-told-to-use-ai-to-enhance-customer-experience-not-burden-them-with",
    image: "43.jpeg",
  },
  {
    title: "Respect your customer’s time and they will reward you for it",
    link: "https://www.khaleejtimes.com/uae/respect-your-customers-time-and-they-will-reward-you-for-it",
    image: "44.jpeg",
  },
  {
    title: "Hany Mokhtar speaks about CX landscape ahead of CX Evolve 2022 Summit",
    link: "https://www.khaleejtimes.com/kt-network/hany-mokhtar-speaks-about-cx-landscape-ahead-of-cx-evolve-2022-summit",
    image: "45.jpeg",
  },
  {
    title: "Customer experience: Cigna creates brand loyalty and lead to competitive advantage",
    link: "https://www.khaleejtimes.com/business/customer-experience-cigna-creates-brand-loyalty-and-lead-to-competitive-advantage",
    image: "46.jpeg",
  },
  {
    title: "Businesses are now more educated about CX, says Chalhoub Group official",
    link: "https://www.khaleejtimes.com/business/businesses-are-now-more-educated-about-cx-says-chalhoub-group-official",
    image: "47.jpeg",
  },
];
