import React from "react";

import Footer from "components/Footer.js";

import { Container, Row, Col } from "reactstrap";

function AgendaPage() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className="py-4">
            <p>
              Khaleej Times is committed to protecting your privacy and
              developing technology that gives you the most powerful and safe
              online experience. This Statement of Privacy applies to all the
              event websites by Khaleej Times and governs data collection and
              usage. By using the Khaleej Times event website, you consent to
              the data practices described in this statement.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">
                Collection of your Personal Information
              </strong>
              <br />
              &nbsp;
              <br />
              Khaleej Times collects personally identifiable information, such
              as your e-mail address, name, home or work address or telephone
              number.
              <br />
              &nbsp;
              <br />
              There is also information about your computer hardware and
              software that is automatically collected by Khaleej Times. This
              information can include: your IP address, browser type, domain
              names, access times and referring Web site addresses. This
              information is used by Khaleej Times for the operation of the
              service, to maintain quality of the service, and to provide
              general statistics regarding use of the Khaleej Times Web site.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">
                Use of your Personal Information
              </strong>
              <br />
              &nbsp;
              <br />
              Khaleej Times collects and uses your personal information to
              operate the Khaleej Times Web site and deliver the services you
              have requested. Khaleej Times also uses your personally
              identifiable information to inform you of other products or
              services available from Khaleej Times and its affiliates. Khaleej
              Times may also contact you via surveys to conduct research about
              your opinion of current services or of potential new services that
              may be offered. Khaleej Times does not sell, rent or lease its
              visitor lists to third parties.
              <br />
              &nbsp;
              <br />
              Khaleej Times may, from time to time, share your personal
              identifiable information with external business partners about a
              particular offering that may be of interest to you. In those
              cases, your unique personally identifiable information (e-mail,
              name, address, telephone number) is transferred to the third
              party.
            </p>
            <p>
              &nbsp;
              <br />
              <strong className="text-700">Use of Cookies</strong>
              <br />
              &nbsp;
              <br />
              The Khaleej Times Web site use "cookies" to help you personalize
              your online experience. A cookie is a text file that is placed on
              your hard disk by a Web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you.
              <br />
              &nbsp;
              <br />
              One of the primary purposes of cookies is to provide a convenience
              feature to save you time. The purpose of a cookie is to tell the
              Web server that you have returned to a specific page. For example,
              if you personalize Khaleej Times pages or services, a cookie helps
              Khaleej Times to recall your specific information on subsequent
              visits.
              <br />
              &nbsp;
              <br />
              You have the ability to accept or decline cookies. Most Web
              browsers automatically accept cookies, but you can usually modify
              your browser setting to decline cookies if you prefer. If you
              choose to decline cookies, you may not be able to fully experience
              the interactive features of the Khaleej Times services or Web
              sites you visit.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">Third Party Cookies</strong>
              <br />
              &nbsp;
              <br />
              In the course of serving advertisements to this site, our
              third-party advertiser may place or recognize a unique "cookie" on
              your browser.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">Newsletters</strong>
              <br />
              &nbsp;
              <br />
              They can choose to not receive these messages by using the
              &ldquo;one-click&rdquo; opt out/ unsubscribe option available at
              the bottom of every newsletter.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">
                Security of your Personal Information
              </strong>
              <br />
              &nbsp;
              <br />
              Khaleej Times secures your personal information from unauthorized
              access, use or disclosure. Khaleej Times secures the personally
              identifiable information you provide on computer servers in a
              controlled, secure environment, protected from unauthorized
              access, use or disclosure. When personal information (such as a
              credit card number) is transmitted to other Web sites, it is
              protected through the use of encryption, such as the Secure Socket
              Layer (SSL) protocol.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">Changes to this Statement</strong>
              <br />
              &nbsp;
              <br />
              Khaleej Times will occasionally update this Statement of Privacy
              to reflect company and customer feedback. Khaleej Times encourages
              you to periodically review this Statement to be informed of how
              Khaleej Times is protecting your information.
              <br />
              &nbsp;
              <br />
              <strong className="text-700">Contact Information</strong>
              <br />
              &nbsp;
              <br />
              Khaleej Times welcomes your comments regarding this Statement of
              Privacy. For any questions or clarification please contact us at
              <br />
              &nbsp;
              <br />
              Head Office, Al Quoz Industrial Area 1, Dubai
              <br />
              P.O. Box 11243,&nbsp;
              <br />
              Tel: 04-3383535,&nbsp;
              <br />
              Fax: 04-3383345&nbsp;
              <br />
              E-mail: ktimes@emirates.net.ae
            </p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default AgendaPage;
