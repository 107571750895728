import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Sponsor({ sponsors }) {
  const rows = [
    ["LEAD SPONSOR"],
    ["STRATEGIC PARTNER"],
    ["NETWORKING PARTNER"],
    ["ECOSYSTEM PARTNER"],
    ["SUPPORTED BY"],
  ];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",

          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="pt-3 text-meta-g meta-title text-center"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>2022 SPONSORS & PARTNERS</b>
              </h1>
            </Col>
            <Col lg={12}>
              <Container>
                {rows.map((row) => (
                  <Row>
                    {row.map((sponsor) =>
                      sponsors[sponsor].map((s) => (
                        <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <h3
                              className="text-dark text-700 text-uppercase text-center pb-3"
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingTop: s.title && "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: !s.title ? s.type : "",
                              }}
                            ></h3>
                            <a href={`/meta-vision2022/sponsors/${s.pageLink}`}>
                              <Card
                                className="mx-auto"
                                style={{ borderRadius: 0, maxWidth: s.size }}
                              >
                                <img
                                  src={require(`assets/sponsors/${s.image}`)}
                                  alt={s.name}
                                  width="100%"
                                  className="rounded-0"
                                />
                              </Card>
                            </a>
                          </ScrollAnimation>
                        </Col>
                      ))
                    )}
                  </Row>
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
