export const speakers = [
  {
    name: 'His Excellency Dr. Thani Bin Ahmed Al Zeyoudi',
    title: 'Minister of State for Foreign Trade',
    company: 'Ministry of Economy, UAE',
    image: 'thani.jpg',
    text: [
      `H.E Dr. Thani bin Ahmed Al Zeyoudi, was appointed as Minister of State for Foreign Trade in July 2020 to oversee
    the development of non-oil foreign trade and the expansion of global trade partnerships and increase Exports.
    In addition to supervising the portfolio of retaining and attracting the best talents and competencies serving the
    strategic orientation of the UAE, His Excellency also leads the efforts to enhance the UAE’s investment climate in
    order to achieve the country’s economic interests and strategic goals, thereby strengthening its position as a
    prime destination for business and investments at both regional and global levels.`,
      `
    Prior to his appointment as the Minister of State for Foreign Trade, H.E held the position of Minister of Climate
    Change and Environment and supervised the implementation of the country’s strategies at the domestic level,
    for environment and climate action, agricultural development, animal husbandry and fisheries, in addition to
    combating desertification and preserving biodiversity leading to enhance the sustainability of natural systems,
    food diversity and the transition to a green economy.`,
      `
    rough diverse Global participations, HE led the UAE’s efforts to mitigate and adapt to the repercussions of
    climate change, and protect the country's natural ecosystem by developing and implementing a set of eective
    policies and initiatives to achieve sustainable development at both regional and international levels and by
    strengthening existing and new partnerships with the government and private sectors that are capable of
    increasing the contribution of environment, agricultural, livestock and fisheries sectors to ensuring food security
    and biosafety in the country as well as increasing the quality of national production.`,
      `
    Previously and after he served as the Director of the Department of Energy and Climate Change at the Ministry
    of Foreign Affairs and International Cooperation, H.E Al Zeyoudi was appointed as the UAE’s Permanent
    Representative to the International Renewable Energy Agency (IRENA) as extraordinary Ambassador.
    Minister Al Zeyoudi started his career as an oil engineer at the Abu Dhabi Marine Operating Company
    (ADMA-OPCO), and then moved to Abu Dhabi Future Energy Company (Masdar), where he worked on the
    development of renewable and clean energy technologies and solutions, and also played a key role in the success
    of the UAE’s 2009 campaign to host the headquarters of the International Renewable Energy Agency (IRENA) in
    the capital City, Abu Dhabi.`,
      `
    In 2020, HE. was chosen by the Davos World Economic Forum to become a member of the Young Global
    Leadership Council affliated with the Forum, recognizing his outstanding international efforts in the field of
    environment and climate. In 2015, Dr. Al Zeyoudi received the first award for Excellence from the Cooperation
    Council for the Arab States of the Gulf in recognition of his pioneering efforts in the field of renewable energy.
    H.E Dr. Thani Bin Ahmed Al Zeyoudi holds a Bachelor's degree in Petroleum Engineering from the University of
    Tulsa, an MBA from the New York Institute of Technology, an MBA in Project Management from the British
    University in Dubai, and a PhD in Strategy, Program and Project Management from Skema Business School in
    France.`,
    ],
  },
  {
    name: 'His Excellency Sunjay Sudhir',
    title: '',
    company: 'Ambassador of India to UAE',
    image: 'sanjay.jpg',
    text: [
      'Mr. Sunjay Sudhir joined the Indian Foreign Service in 1993. He served as High Commissioner of India to the Maldives from March 2019 to Oct 2021. Prior to this, hewas Head of International Cooperation Division at the Ministry of Petroleum & Natural Gas headingin New Delhi (2015-19).',
      'In a diplomatic career spanning about three decades Mr Sudhir has done six overseas postings. These include Consul General of India in Sydney (2014-15); Counsellor at the Permanent Mission of India to the World Trade Organisation, Geneva (2007-11) where he dealt with IPRs, RTAs and Trade and Development, Head of the Economic and Commercial Wing at the Indian High Commission, Colombo (2004-07), Second Secretary (Political, Information and Culture) at the Indian Embassy, Damascus (1997-2000) and Third Secretary at the Indian Embassy, Cairo (1995-97). His postings at Head Quarters include Joint Secretary and Head of the Office of External Affairs Minister (2012-14), Joint Secretary (SAARC), Deputy Chief of Protocol (2002-04), and in the Europe West Division (2000).',
      'Mr. Sudhir holds degree of Bachelor of Technology from the Indian Institute of Technology – New Delhi and has pursued Diplomatic Studies at Oxford University (UK). In International Trade, Mr Sudhir has done Certificate Courses on TRIPS Agreement and Public Health, WTO Law and Jurisprudence, and WTO Dispute Settlement.In the field of Energy, he has been Director on the Boards of ONGC Videsh Ltd, Oil India Ltd and Indian Strategic Petroleum Reserves Ltd. He is currently an Associate Editor on the Editorial Board of OPEC Energy Review.',
      'Mr Sudhir is married and has two daughters',
    ],
  },
  {
    name: 'His Excellency Dr. Ahmed Abdul Rahman Albanna',
    title: '',
    company: 'UAE’s Ambassador to India, UAE Ministry',
    image: 'drahmedalbanna.jpg',
    text: [],
  },
  {
    name: 'Dr. Aman Puri ',
    title: '',
    company: 'Consul General of India',
    image: 'aman.jpg',
    text: [
      'Dr. Aman Puri assumed charge as Consul General of India, Dubai in July, 2020. He is a member of the Indian Foreign Service, having joined in 2003. His overseas assignments include serving as the Consul General of India, Birmingham (U.K), and at the Indian Mission to the European Union, Belgium and Luxembourg, based at Brussels.',
      'At the headquarters, he has held the office of Deputy Chief of the Protocol which involved handling the foreign visits of Prime Minster of India & similar incoming visits. He also served as the Regional Passport Officer, New Delhi.',
      `Dr. Puri, (born 1976) did his schooling from St. John's High School, Chandigarh, and qualified as a Dentist before joining the Indian Foreign Service. `,
    ],
  },
  {
    name: 'Sujeeth Kumar',
    title: 'Co-Founder ',
    company: 'Udaan',
    image: 'sujeet.jpg',
    text: [
      'Sujeet Kumar is a Co-founder of udaan, India’s largest B2B eCommerce company. He founded udaan in 2016 along with Vaibhav Gupta and Amod Malviya, both former colleagues of Sujeet and senior executives at Flipkart.',
      'Prior to founding udaan, Sujeet was President, Operations at Flipkart where he built operations and supply-chain network for the company. He conceptualised and built the logistics and supply-chain arm of Flipkart - Ekart, which today is India’s largest logistics and supply chain company and the backbone of ecommerce distribution.',
      'Sujeet has been associated with the Indian eCommerce and start-up industry since its beginning. He has been investing and mentoring various start-ups for over a decade now and sits on the Boards of some of them. With a philosophy to give back to the eCommerce and start-up ecosystem, Sujeet has invested in over 100 start-ups till date and continues to do so. Some of his investee companies have already become unicorns and are leading players in different segments.',
      'Sujeet has done his BTech in Civil Engineering from IIT, Delhi.',
    ],
  },
  {
    name: 'Priya Rawat',
    title: 'Chief Operating Officer',
    company: 'Invest India',
    image: 'priya.jpg',
    text: [],
  },
  {
    name: 'Neeraj Gupta',
    title: 'Chief Executive  Officer',
    company: 'Policybaazar.ae',
    image: 'neeraj.jpg',
    text: [],
  },
  {
    name: 'Rajeev Kumar',
    title: 'Vice Chairman',
    company: 'Niti Aayog',
    image: 'Rajeev Pic.jpg',
    text: [],
  },
  {
    name: 'Eileen Pryer',
    title: 'Director of Marketing, Admissions & International Recruitment',
    company: 'University College Birmingham UK, Accredited by the University of Warwick',
    image: 'eileen.png',
    text: [
      `Born in Ireland, educated in Birmingham and an alumni of the University of Manchester.`,
      `I am part of the Universities Senior Management team responsible for marketing the university and all admissions processes which ensures fare access for all students. In addition, I lead on the UK government initiative ‘UniConnect’ for the West Midlands region. Uniconnect was developed to ensure that schools and colleges in areas of low deprivation were supported by local universities to engage with young people whose families have never been to college or University. University College Birmingham (UCB) is totally committed to raising attainment and improving aspirations. Education should be accessible for everyone, including young and mature students. I am extremely committed to developing pathways that provides opportunity for UK and international student to progress and realise their potential.`,
      ` UCB is a highly specialist university which has developed an outstanding reputation over the last 100 years in the areas of Hospitality, Culinary Arts, Tourism and Aviation Management. However, our curriculum has extended considerably over the last 10 years and our portfolio now includes Finance and Accounting, Nutrition, Aesthetics, Nursing, Physiotherapy and for 2023, we will add Engineering and Construction to our portfolio.`,
      `My role also includes responsibility for all international partnerships which in its traditional format, allows progression to UCB’s undergraduate or postgraduate degree programmes, many of which are accredited by the university of Warwick (ranked 6th in the UK).`,
      `Covid has restricted our opportunity to travel and develop new partnerships and to overcome this, I was tasked with developing international offices in India, China and Malaysia. These key support networks were opened and fully staffed at the end of 2020 with further plans to open in Dubai and Nigeria next year.],`,
    ],
  },
  {
    name: 'Sriranga Sampathkumar',
    title: 'VP and General Manager – Middle East and Africa',
    company: 'Infosys, India',
    image: 'Sriranga Photograph.jpg',
    text: [],
  },

  {
    name: 'Binu Parthan',
    title: 'Head - Regions',
    company: 'The International Renewable Energy Agency (IRENA)',
    image: 'Binu.jpg',
    text: [],
  },

  {
    name: 'Shradha Sharma',
    title: 'Co-founder',
    company: 'YourStory, India',
    image: 'Shradha.png',
    text: [],
  },

  {
    name: 'Satyam Bose',
    title: 'Chairman & Chief executive Officer',
    company: 'Virenxia, UAE',
    image: 'Photograph-Mr.Satyam Bose.jpg',
    text: [],
  },

  {
    name: 'Sanjay Kumar',
    title: 'Chief General Manager – Business Development',
    company: 'Hindustan Petroleum Corporation Limited',
    image: 'SANJAY KUMAR PROFILE PIC-1.jpg',
    text: [],
  },

  {
    name: 'Ahmed Elbermbali',
    title: 'Managing Director',
    company: 'Clean Energy Business Council, UAE',
    image: 'Elbermbali.jpg',
    text: [],
  },

  {
    name: 'Dr. Hitesh Doshi',
    title: 'Chairman & Managing Director',
    company: 'Waaree Group, India',
    image: 'Dr. Hitesh.jpg',
    text: [],
  },

  {
    name: 'Eng. Nawal Alhanaee',
    title: 'Director - Future Energy Department',
    company: 'Ministry of Energy & Infrastructure, UAE',
    image: 'Nawal Yousif Ibrahim Al-Hanaee (1).jpg',
    text: [],
  },

  {
    name: 'Shivendra Singh',
    title: 'Vice President & Head - Global Trade Development',
    company: 'NASSCOM, India',
    image: 'Shivendra pic.jpg',
    text: [],
  },

  {
    name: 'Shrini Viswanath',
    title: 'Co-founder',
    company: 'UpStox, India',
    image: 'shrini.jpg',
    text: [],
  },
  {
    name: 'Martin Nagell',
    title: 'Advisor, Office of the CEO',
    company: 'Masdar, UAE',
    image: 'Martin Nagell-image.jpg',
    text: [
      `Martin Nagell has more than 22 years of experience in global
capital markets and is currently working as an Adviser in the
office of the CEO at Masdar, Abu Dhabi’s renewable energy
company. In his current role, he is responsible for overseeing
high-impact initiatives that will have future importance for the
company. His special interests include the application of
disruptive block-chain based solutions in renewable energy.
Martin has been with Masdar since 2009, having held a range
of roles, including Head of Asset Management and Head of
Strategy.`,
      `During his time with the Boston Consulting Group, Martin
advised global companies on strategy, value-based
management, quantitative risk management, acquisition
related advisory, merger and post-merger integration,
corporate governance and organizational development. His
clients included some of the world's largest sovereign wealth
funds, corporate, investment and universal banks, investment
companies, utilities and telecoms.`,
      `Martin also worked at Danske Bank, where he provided
financial advisory services to corporate clients and managed
resulting transactions on their behalf. His experience also
includes market making and trading in various financial
instruments in the capital market division of Danske Bank.
Martin holds an MBA from Instituto de Empresa, and an MSc in
Finance from the Norwegian School of Economics.`,
    ],
  },
  {
    name: 'Prakash Hinduja',
    title: 'Chairman',
    company: 'Hinduja Group, India',
    image: 'Prakash Pic.jpg',
    text: [],
  },
  {
    name: 'Arun Chawla',
    title: 'Director General',
    company: 'Federation of Indian Chambers of Commerce & Industry',
    image: 'Arun Chawla Pic.png',
    text: [],
  },
  {
    name: 'Abhinav Gupta',
    title: 'Chief Executive Officer',
    company: 'CARS24, Gulf',
    image: 'Abhinav Gupta - CEO CARS24 - Gulf Region[1].png',
    text: [],
  },
  {
    name: 'Nitin Puri',
    title: 'Head- e Marketplace',
    company: 'Innoterra, India',
    image: 'Nitin Puri Pic.jpg',
    text: [],
  },
  {
    name: 'Dr. Reji Kurien Thomas',
    title: 'Group Chief Technology Officer',
    company: 'Virenxia, UAE',
    image: 'reji.jpg',
    text: [],
  },
  {
    name: 'Sanjeev Kumar',
    title: 'Chairman & Managing Director and Director (Projects)',
    company: 'TCIL, Government of India',
    image: 'Sanjeev Pic.jpg',
    text: [],
  },
  {
    name: 'Ramesh Ram',
    title: 'Head of Alliances and Partnerships, Middle East & Africa',
    company: 'Tech Mahindra, UAE',
    image: 'Ram Tech Mahindra pic.jpg',
    text: [],
  },
  {
    name: 'Ghanim Mohammad  Al Falasi',
    title: 'Senior Vice President - Technology & Entrepreneurship',
    company: 'Dubai Silicon Oasis, UAE',
    image: 'Ghanim Al Falasi.jpg',
    text: [],
  },

//   {
//     name: 'Sameh Abdulla Al Qubaisi',
//     title: 'Director General of Economic Affairs',
//     company: 'Abu Dhabi Department of Economic Development',
//     image: 'adro.jpg',
//     text: [
//       ` His Excellency Sameh Al Qubaisi is the Director General of
// Economic Affairs at the Abu Dhabi Department of Economic
// Development (ADDED). In this role, Al Qubaisi leads the
// teams responsible for delivering Abu Dhabi’s economic
// strategy. More specifically, he oversees SME policymaking,
// logistics and trade facilitation, the Competitiveness Office of
// Abu Dhabi, the Industrial Development Bureau, and the Abu
// Dhabi Residents Office.`,
//       `Sameh Al Qubaisi is a senior government executive with extensive experience in economic
// policymaking. With a background in global banking and finance, he has already led various high-impact
// strategic projects aimed at enhancing Abu Dhabi’s economy, capital markets, livability, ease of doing
// business, and investments attraction.`,
//       `Before taking on his responsibilities at ADDED, Sameh Al Qubaisi was the Executive Director of
// Business Enterprise at the Abu Dhabi Investment Office (ADIO) where he was primarily responsible for
// investments and Public Private Partnerships. He played a key role in scaling up ADIO’s coverage,
// capabilities, and offerings.`,
//       `Prior to joining the Government of Abu Dhabi, he led a 15-year career in global banking and finance.
// His long-standing expertise in wholesale banking, risk management and global markets plays a crucial
// role in his efforts towards achieving macro-economic diversification initiatives.
// Al Qubaisi spent over 10 years at the National Bank of Abu Dhabi NBAD (now First Abu Dhabi Bank
// FAB), with his most recent roles as CEO of its Northeast Asia operations, as well as Managing Director
// for its Wholesale Business- Asia based out of Hong Kong. Before that, he was the Managing Director
// of Global Markets Coverage at NBAD, a business he built from the ground up. He also held various
// leading roles at CAPM Investments, Finance House, Standard Chartered Bank, HSBC, and National
// Bank of Dubai.`,
//       `Sameh Al Qubaisi holds a B.A. in International Relations and two minors in Political Science and
// Economics from Rollins College, USA.`,
//     ],
//   },
];

export const pastspeakers = [];

const sponsors = [
  {
    name: 'allana ',
    pageLink: 'allana ',
    type: 'LEAD SPONSOR',
    image: 'allana.jpg',
    text: [],
    link: 'https://allana.com/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'CAR24 ',
    pageLink: 'CAR24 ',
    type: 'LEAD SPONSOR',
    image: 'car24.jpg',
    text: [],
    link: 'https://www.cars24.com/ae/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'Policybazaar ae',
    pageLink: 'Policybazaar ae',
    type: 'TECHNOLOGY SPONSOR',
    image: 'pb.jpg',
    text: [],
    link: 'https://www.policybazaar.ae',
    size: '100%',
    grid: 4,
  },

  {
    name: 'Virenxia ',
    pageLink: 'Virenxia ',
    type: 'STRATEGIC SPONSOR',
    image: 'vinrexia.png',
    text: [],
    link: 'https://www.virenxia.com/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'GEW ',
    pageLink: 'GEW ',
    type: 'STRATEGIC SPONSOR',
    image: 'gew.jpg',
    text: [],
    link: 'http://www.galadariindustrialgroup.com/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'GB ',
    pageLink: 'GB ',
    type: 'STRATEGIC SPONSOR',
    image: 'gb.jpg',
    text: [],
    link: 'https://www.galadarigroup.com/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'ITS ',
    pageLink: 'ITS ',
    type: 'STRATEGIC SPONSOR',
    image: 'ITS.jpg',
    text: [],
    link: 'https://itstraveldxb.com/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'Invest India',
    pageLink: 'Invest India',
    type: 'KNOWLEDGE PARTNER',
    image: 'invest.jpg',
    text: [],
    link: 'https://www.investindia.gov.in/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'FICCI',
    pageLink: 'FICCI',
    type: 'KNOWLEDGE PARTNER',
    image: 'ficci.png',
    text: [],
    link: 'https://ficci.in/',
    size: '100%',
    grid: 4,
  },
  {
    name: 'Bank of Baroda',
    pageLink: 'Bank of Baroda',
    type: 'BANKING SPONSOR',
    image: 'baroda.png',
    text: [],
    link: 'https://www.bankofbarodauae.ae/',
    size: '100%',
    grid: 4,
  },
];
const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy('type');
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    title: 'His Excellency Dr Thani Bin Ahmed Al Zeyoudi, Minister of State for Foreign Trade, Ministry of Economy, UAE, at the opening Keynote Address',
    link: 'https://www.linkedin.com/posts/khaleejtimes_uaeindiatrade-cepa-postcovidstate-activity-6909386459443580928-affl/',
    image: '42.jpeg',
  },
  {
    title: 'Dr. Aman Puri, Consul General, Government of India, at the welcome note',
    link: 'https://www.linkedin.com/posts/khaleejtimes_uaeindiatrade-cepa-uae-activity-6909390036023009280-f3MS/',
    image: '41.jpeg',
  },
  {
    title: 'Abhinav Gupta, Chief Executive Officer, CARS24, Gulf Opening Case Study',
    link: 'https://www.linkedin.com/posts/khaleejtimes_uaeindiatrade-cepa-uae-activity-6909395953217085440-YYZk/',
    image: '40.jpeg',
  },
  {
    title: 'CEPA to trigger investment flows, create new opportunities for key sectors',
    link: 'https://www.linkedin.com/posts/khaleejtimes_uaeindiatrade-cepa-uae-activity-6909709845411610624-IabU/',
    image: '39.jpeg',
  },
  {
    title: 'Companies set to benefit as soon as CEPA comes into effect',
    link: 'https://www.linkedin.com/posts/khaleejtimes_uaeindiatrade-cepa-uae-activity-6909755154472853504-y9vp/',
    image: '38.jpeg',
  },
  {
    title: 'India-UAE investment forum set to boost businesses',
    link: ' https://www.khaleejtimes.com/uae/india-uae-investment-forum-set-to-boost-businesses',
    image: '37.jfif',
  },
  {
    title: 'India-Israel-UAE-US: New Quad',
    link: 'https://www.khaleejtimes.com/kt-engage/india-israel-uae-us-new-quad',
    image: '20.jfif',
  },

  {
    title: 'Boosting Trade and Commerce',
    link: 'https://www.khaleejtimes.com/supplements/boosting-trade-and-commerce',
    image: '21.jfif',
  },

  {
    title: 'India-UAE Partners in Development and Growth',
    link: 'https://www.khaleejtimes.com/supplements/india-uae-partners-in-development-and-growth',
    image: '22.jfif',
  },

  {
    title: 'India hopes to sign new trade pact with UAE early next year: Dr Aman Puri',
    link: 'https://www.khaleejtimes.com/expo/india-hopes-to-sign-new-trade-pact-with-uae-early-next-year-dr-aman-puri',
    image: '23.jfif',
  },

  {
    title: 'UAE-India explore ways to facilitate further investment in key priority sectors',
    link: 'https://www.khaleejtimes.com/uae/uae-india-explore-ways-to-facilitate-further-investment-in-key-priority-sectors',
    image: '24.jfif',
  },

  {
    title: 'UAE-India ties on cusp of big leap: Piyush Goyal',
    link: 'https://www.khaleejtimes.com/uae/uae-india-ties-on-cusp-of-big-leap-piyush-goyal',
    image: '25.jfif',
  },

  {
    title: 'Economic partnership deal will help India, UAE to serve larger international markets',
    link: 'https://www.khaleejtimes.com/business/economic-partnership-deal-will-help-india-uae-to-serve-larger-international-markets',
    image: '26.jfif',
  },

  {
    title: "Expo 2020 Dubai: India's participation will boost UAE ties, says Modi",
    link: 'https://www.khaleejtimes.com/expo/expo-2020-dubai-indias-participation-will-boost-uae-ties-says-modi',
    image: '27.jfif',
  },

  {
    title: 'UAE-India partnership can serve the world: Piyush Goyal',
    link: 'https://www.khaleejtimes.com/uae/uae-india-partnership-can-serve-the-world-piyush-goyal',
    image: '28.jfif',
  },

  {
    title: 'Facilitating FDI in India and UAE',
    link: 'https://www.khaleejtimes.com/supplements/facilitating-fdi-in-india-and-uae-2',
    image: '29.jfif',
  },

  {
    title: 'India, UAE to start talks for trade pact on Thursday: Goyal',
    link: 'https://www.khaleejtimes.com/business/india-uae-to-start-talks-for-trade-pact-on-thursday-goyal',
    image: '30.jfif',
  },

  {
    title: '66% of Indian expats plan to invest more in UAE: HSBC survey',
    link: 'https://www.khaleejtimes.com/business/66-of-indian-expats-plan-to-invest-more-in-uae-hsbc-survey',
    image: '31.jfif',
  },

  {
    title: 'Emaar to develop shopping mall in India’s Srinagar',
    link: 'https://www.khaleejtimes.com/business/emaar-to-develop-shopping-mall-in-indias-srinagar',
    image: '32.jfif',
  },

  {
    title: "Indian minister urges industrialists to use UAE's infrastructure for growth",
    link: 'https://www.khaleejtimes.com/business/indian-minister-urges-industrialists-to-use-uaes-infrastructure-for-growth',
    image: '33.jfif',
  },

  {
    title: 'TA’ZIZ and Reliance launch venture for $2b chemicals projects',
    link: 'https://www.khaleejtimes.com/business/taziz-and-reliance-launch-venture-for-2b-chemicals-projects',
    image: '34.jfif',
  },

  {
    title: 'India can help UAE bring down healthcare costs: Minister',
    link: 'https://www.khaleejtimes.com/uae/india-can-help-uae-bring-down-healthcare-costs-minister',
    image: '35.jfif',
  },

  {
    title: 'UAE ups profile at Indian Ocean group',
    link: 'https://www.khaleejtimes.com/uae/uae-ups-profile-at-indian-ocean-group',
    image: '36.jfif',
  },
];
