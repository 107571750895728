import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
function TextGrid() {
  let pageHeader = React.createRef();

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "6rem 0",
          // backgroundImage: "url(" + require("assets/images/Asset24.png") + ")",
          // backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-left">
            <Col lg={6}>
              <h1
                className=" section-title-cx pt-3 text-meta-g meta-title"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>WHAT IS THE METAVERSE</b>
              </h1>
              <h3 className="text-400-cx text-dark">
                The metaverse is increasingly being perceived as being an integral part of 'Web
                3.0'. This is the evolution of the world wide web from its initial form (Web
                1.0,where you passively accessed content online) through its current form (Web 2.0 —
                where we became content creators via social media platforms) to something new that
                is built on a foundation of blockchain technology. The key difference is how
                blockchain facilitates ownership of digital assets, which can include "virtual land"
                inside this ethereal concept of the metaverse.
              </h3>
              <br />
              <h1
                className=" pt-3 text-meta-g meta-title"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>NO GIMMICK</b>
                <br />
              </h1>
              VR, and by extension the metaverse, is no gimmick — it genuinely is the natural
              evolution of computing. As humans, we exist in a 3D world. So, it makes sense for us
              to engage with digital content in 3D, too.
            </Col>
            <Col lg={5} className="align-self-center">
              <img src={require("assets/images/metaverse.jpg")} alt="meta1" width="100%" />
            </Col>
          </Row>
          <Row className="justify-content-left py-4">
            <Col lg={6}>
              <img src={require("assets/images/nft.png")} alt="meta1" width="100%" />
            </Col>
            <Col lg={6} className="align-self-center">
              <h1
                className=" section-title-cx pt-3 text-meta-g meta-title "
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>NFT</b>
              </h1>
              <h3 className="text-400-cx text-dark">
                Non-fungible tokens — records of digital ownership stored in the blockchain — will
                be the linchpin of the metaverse economy, by enabling authentication of possessions,
                property and even identity. <br />
                <br />
                <br />
                Think of them as certificates of ownership that exist on the blockchain. They are
                created when a digital file is minted. This means that a certificate of ownership
                and originality is generated via cryptocurrency and sold/granted to the new owner.
              </h3>
            </Col>
          </Row>

          <Row className="justify-content-left">
            <Col lg={6}>
              <h1
                className=" section-title-cx pt-3 text-meta-g meta-title"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>MASS ADOPTION</b>
              </h1>
              <h3 className="text-400-cx text-dark">
                We are still easily five to 10 years away from true mass adoption. This is still the
                Nokia 3210 stage of VR hardware, not the iPhone and the current iteration of the
                metaverse is probably more in line with the AOL days of Web 1.0 or the MySpace days
                of Web 2.0.
              </h3>
              <br />
              <h1
                className=" pt-3 text-meta-g meta-title"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>THE IMPACT IN THE UAE </b>
                <br />
              </h1>
              The UAE has shown time and time again that it is a true hub of innovation not only in
              the Middle East but the entire world. Recent announcements such as the One Human
              Reality project, the formation of the Virtual Asset Regulatory Authority (Vara) and
              the welcoming of multiple blockchain-focused companies to the country have shown not
              only a willingness to be open to these game-changing technologies but a desire to lead
              the way in terms of their development and adoption.
            </Col>
            <Col lg={6} className="align-self-center">
              <img src={require("assets/images/mass.jpg")} alt="meta1" width="100%" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.virenxia.com/", image: "main1.png" },
  // { link: "", image: "main2.jpg" },
  // { link: "https://allana.com/", image: "main3.jpg" },
  // { link: "https://www.tatacommunications.com/", image: "4.jpg" },
  // { link: "https://www.poly.com/ae/en", image: "5.jpg" },
  // { link: "https://bit.ly/2p0kYoJ", image: "6.png" },
  // { link: "https://www.teamviewer.com/tensor", image: "7.png" },
  // { link: "http://www.blueprism.com/", image: "8.png" },
];

export default TextGrid;
