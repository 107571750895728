import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setScroll(false);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: "50px" }}
        color-on-scroll='300'
        expand='lg'
      >
        <Container fluid>
          <div className='navbar-translate'>
            <NavbarBrand
              data-placement='bottom'
              to='/'
              target='_blank'
              className='p-0'
              tag={Link}
            >
              <img
                src={require(`assets/images/logo/navbarlogo.png`)}
                className=''
                width='100%'
                style={{ maxWidth: "200px" }}
                alt='logo'
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames(
                "navbar-toggler text-right navbar-toggler",
                {
                  toggled: navbarCollapse,
                }
              )}
              onClick={toggleNavbarCollapse}
            >
              <span className='navbar-toggler-bar bar1 text-white' />
              <span className='navbar-toggler-bar bar2' />
              <span className='navbar-toggler-bar bar3' />
            </button>
          </div>
          <Collapse
            className='justify-content-end'
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem className='d-flex align-middle'>
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2`}
                    style={{
                      color: scroll ? "#1d1960" : "#1d1960",
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                  >
                    <i
                      className={`fa fa-${s.name}`}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset='100'
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className='nav-link navbar-item-custom text-600'
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className='m-0 mt-1 p-0' align='center' />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className='nav-link navbar-item-custom text-600'
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className='m-0 mt-1 p-0' align='center' />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className='nav-link navbar-item-custom text-600'
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className='m-0 mt-1 p-0' align='center' />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className='navbar-item-custom text-600'
                      style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className='navbar-item-custom text-400'
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
              <NavItem>
                <NavLink
                  to='/uae-india-investment-forum/sponsor'
                  tag={Link}
                  className='btn btn-primary rounded-0 navbar-item-custom text-400 text-white px-1'
                  style={{ opacity: "1", backgroundColor: "#051245" }}
                >
                  <p className='m-0 text-700' style={{ color: "#fff" }}>
                    SPONSORSHIP ENQUIRY
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/uae-india-investment-forum/register'
                  tag={Link}
                  className='btn btn-primary rounded-0 navbar-item-custom text-400 text-white px-1'
                  style={{ opacity: "1", backgroundColor: "#051245" }}
                >
                  <p className='m-0 text-700' style={{ color: "#fff" }}>
                    REGISTER NOW
                  </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "HOME",
    link: "/uae-india-investment-forum/",
    page: undefined,
    items: undefined,
    grid: 1,
  },

  {
    name: "OVERVIEW",
    link: "",
    page: "/",
    items: [
      { name: "ABOUT", link: "/#about" },
      {
        name: "THE ORGANIZERS",
        link: "/uae-india-investment-forum/overview/the-organiser",
      },
      {
        name: "CONTACT US",
        link: "/uae-india-investment-forum/overview/contact-us",
      },
    ],
    grid: 1,
  },

  {
    name: "SPEAKERS",
    link: "/uae-india-investment-forum/#speakers",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: "AGENDA",
    link: "/uae-india-investment-forum/agenda",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "PARTNERS",
  //   link: "/sponsor/sponsors-partners",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "TRAVEL",
    link: "/uae-india-investment-forum/venue-travel",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "MEDIA",
  //   link: "",
  //   page: "/",
  //   items: [
  //     {
  //       name: "INDUSTRY NEWS",
  //       link: "/media/industry-news",
  //     },
  //     {
  //       name: "BECOME A MEDIA PARTNER",
  //       link: "/media/become-a-media-partner?utm_source=MEDIA",
  //     },
  //   ],
  //   grid: 1,
  // },
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/KTRemoteWorkforceSummit/",
  // },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/global-investment-forum/?viewAsMember=true",
  },
  // {
  //   name: "twitter",
  //   link: "https://twitter.com/IsraelUae",
  // },
];
