import React from 'react';
import Navbar from 'componentsWomen/Navbar.js';
import LandingPageHeader from 'componentsWomen/Headers/LandingPageHeader.js';
import Footer from 'componentsWomen/Footer.js';
import TextGrid from 'componentsWomen/TextGrid.js';
import Agenda from 'componentsWomen/Agenda.js';
import KeyTopics from 'componentsWomen/KeyTopics.js';
import WomenRep from 'componentsWomen/WomenRep.js';

import SpeakerCard from 'componentsWomen/SpeakerCard.js';
import Sponsor from 'componentsWomen/Sponsor.js';
import { sponsorsPage, speakers } from './content';

function Home() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('profile-page');
    return function cleanup() {
      document.body.classList.remove('profile-page');
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <TextGrid />
          <KeyTopics />
          <section id="speakers" />
          <SpeakerCard speakers={speakers} />
          
          <WomenRep />
          <section id="agenda" />
          <Agenda />
          <section id="sponsors" />
          <Sponsor sponsors={sponsorsPage} />
          <div className="main"></div>
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
