import React from "react";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import ContactUs from "components/ContactUs";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <ContactUs />

      <Footer />
    </>
  );
}

export default RegisterPage;
