import React from 'react';

import { Container, Row, Col, Card } from 'reactstrap';

import Slider from 'react-slick';

function Sponsor({ sponsors }) {
  const rows = [['IFTAAR SPONSOR']];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: 'p-10',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: 'fff',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="title mb-2 section-title text-center" style={{ color: '#624332' }}>
                <b>2022 Sponsors & Partners</b>
              </h1>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={'ml-auto mr-auto pb-3'}>
                    <h2
                      className="text-dark text-700  text-center pb-3"
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h2>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <Card className="mx-auto" style={{ borderRadius: 0, maxWidth: s.size }}>
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt="Sponsor logo"
                          width="100%"
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
