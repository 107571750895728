import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function News() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          paddingTop: size ? "8rem" : "3rem",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Industry News</b>
              </h1>
            </Col>
            {news.map((n, index) => (
              <Col lg={4}>
                <Container fluid key={index}>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <Container fluid>
                          <Row>
                            <Col lg={12} style={{ padding: 0 }} className="align-self-center">
                              {n.iframe ? (
                                <div class="embed-responsive embed-responsive-16by9">
                                  <iframe
                                    src={n.iframe}
                                    allowfullscreen
                                    frameborder={0}
                                    title="jpost"
                                  />
                                </div>
                              ) : (
                                <img
                                  src={require(`assets/images/news/${n.image}`)}
                                  alt="meltwater"
                                  width="100%"
                                  style={{ borderRadius: 0 }}
                                />
                              )}
                            </Col>
                            <Col
                              lg={12}
                              className=""
                              style={{
                                padding: "1rem",
                              }}
                            >
                              <h4 className="text-dark" style={{ margin: 0, padding: 0 }}>
                                {n.title}
                              </h4>
                              <br />
                              <h6
                                className="text-dark"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  textTransform: "none",
                                }}
                              >
                                {n.short_description}{" "}
                                <Button
                                  className="btn"
                                  color="info"
                                  size="sm"
                                  href={n.link}
                                  outline
                                  // style={{ minWidth: "100%" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  More
                                </Button>
                              </h6>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const news = [
  
  {
    title: "Businesses are now more educated about CX, says Chalhoub Group official",
    link: "https://www.khaleejtimes.com/business/businesses-are-now-more-educated-about-cx-says-chalhoub-group-official",
    image: "42.jpeg",
  },
  {
    title: "Khaleej Times launches CX Evolve 2022 summit",
    link: "https://www.pressreader.com/uae/khaleej-times/20220709/281943136591658",
    image: "Logo-cx.png",
  },
  {
    link: "https://www.khaleejtimes.com/photos/look-adventurers-off-road-enthusiasts-buckle-up-for-kt-desert-drive",
    image: "1.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/events/kt-desert-drive-over-1400-participants-enjoy-high-octane-off-road-adventure",
    image: "2.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/kt-desert-drive-5-days-to-go-before-the-much-awaited-off-road-adventure",
    image: "3.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/article/five-tips-off-roaders-should-keep-in-mind-before-heading-out",
    image: "4.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/local-events/how-to-prep-your-car-for-kt-desert-drive",
    image: "5.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/kt-desert-drive-keep-your-eyes-on-the-road-your-hand-upon-the-wheel-on-nov-19",
    image: "6.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/entertainment/hurry-and-register-to-experience-the-rush-of-off-roading-at-khaleej-times-desert-drive-on-nov19th20",
    image: "7.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/article/kt-desert-drive-off-roading-with-the-right-tyres-is-more-fun",
    image: "8.jpg",
    title: "KT Desert Drive: Off-roading with the right tyres is more fun",
  },
  {
    link: "https://www.khaleejtimes.com/local-events/castrol-lubricants-partner-for-3rd-edition-of-kt-desert-drive-2",
    image: "9.jpg",
    title: "Castrol Lubricants partner for 3rd edition of KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/khaleej-times-desert-drive-cruises-to-ras-al-khaimah-this-november",
    image: "10.jpg",
    title: "Khaleej Times Desert Drive cruises to Ras Al Khaimah this November",
  },
  {
    link: "https://www.khaleejtimes.com/business/banks-need-to-focus-on-smart-transformation",
    image: "11.jpg",
    title: "Banks need to focus on ‘smart transformation’",
  },
  {
    link: "https://www.khaleejtimes.com/business/innovations-are-driving-banking-financial-services",
    image: "12.jpg",
    title: "Innovations are driving banking, financial services",
  },
  // {
  //   link: "https://www.khaleejtimes.com/business/dont-just-build-for-today-focus-on-tomorrow",
  //   image: "13.jpg",
  //   title: "Don’t just build for today; focus on tomorrow",
  // },
  {
    link: "https://www.khaleejtimes.com/business/cyberattacks-a-question-of-when-it-will-happen-not-if",
    image: "14.jpg",
    title: "Cyberattacks: A question of when it will happen, not if",
  },
  {
    link: "https://www.khaleejtimes.com/uae/uae-on-its-way-to-becoming-global-ai-leader-minister",
    image: "15.jpg",
    title: "UAE on its way to becoming global AI leader: Minister",
  },
  {
    link: "https://www.khaleejtimes.com/tech/artelligence-2021-align-your-data-strategy-with-your-business-goals ",
    image: "16.jpg",
    title: "Artelligence 2021: Align your data strategy with your business goals",
  },
  {
    link: "https://www.khaleejtimes.com/technology/ai-masterclass-at-kts-artelligence-forum-2021",
    image: "17.jpg",
    title: "AI Masterclass at KT’s Artelligence Forum 2021",
  },
  {
    link: "https://www.khaleejtimes.com/corporate/khaleej-times-to-host-4th-artelligence-forum-on-sept-13",
    image: "18.jpg",
    title: "Khaleej Times to host 4th Artelligence forum on Sept 13",
  },
];
