import React from "react";

function ProfilePageHeader({ title, bgColor }) {
  let pageHeader = React.createRef();

  const padding =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? "7rem 0 2rem 0rem"
      : "5rem 0 1rem 0rem";

  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: padding,
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <h1 className={"text-center text-primary section-title"}>
            <b>{title}</b>
          </h1>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ProfilePageHeader;
