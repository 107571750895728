// reactstrap components
import { Container, Col, Row, Button } from 'reactstrap';

import Particles from '../Particles';
import React from 'react';

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(' + require('assets/images/womanbg.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          // background: '#fff',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
        className="section pb-0 pt-5"
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container fluid>
          <Row className="justify-content-center align-items-center">
            <Col
              md={6}
              xs={12}
              className={` px-5 text-center text-white`}
              style={{
                height: '100vh',
                background: 'rgba(0,0,0,0.2)',
                paddingTop: size ? '20vh' : '5rem',
              }}
            >
              <img
                src={require(`assets/images/womenMain.png`)}
                width="100%"
                style={{ maxWidth: '350px' }}
                alt="Finnovex"
              />
              <h1 className="text-600  text-white text-uppercase  mt-3">
                Empowering Women
                In Business Leadership
              </h1>
              <h2 className="text-600 text-uppercase mt-3">20th APRIL 2022, DUBAI</h2>

              <Container fluid>
                <Row className="justify-content-center pt-3">
                  <Col lg={6} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      style={{ width: '99%', background: '#624332' }}
                      href="/sponsor/download-brochure"
                    >
                      <p className="m-0 text-700" style={{ color: '#fff' }}>
                        DOWNLOAD
                        <br />
                        BROCHURE
                      </p>
                    </Button>{' '}
                  </Col>
                  <Col lg={6} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      style={{ width: '99%', background: '#624332' }}
                      href="/register"
                    >
                      <p className="m-0 text-700" style={{ color: '#fff' }}>
                        REGISTER
                        <br />
                        NOW
                      </p>
                    </Button>{' '}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={7} xs={12} className="mb-1"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
