import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

export const newsponsors = [
  {
    name: "IBM",
    image: "ibm.jpg",
    link: "http://ibm.com/",
  },

  { name: "aa", image: "aa.png", link: "https://www.automationanywhere.com/" },
  { name: "AWS", image: "AWS.png", link: "https://aws.amazon.com/" },
  { name: "cisco", image: "cisco.png", link: "https://www.cisco.com/" },
  { name: "crowe", image: "crowe.png", link: "https://www.crowe.com/" },
  { name: "dell", image: "dell.png", link: "https://www.delltechnologies.com/" },
  { name: "google", image: "google.png", link: "https://cloud.google.com/" },
  { name: "lenovo", image: "lenovo.jpg", link: "https://www.lenovo.com/" },
  { name: "mastercard", image: "mastercard.jpg", link: "https://www.mastercard.com" },
  { name: "microsoft", image: "microsoft.png", link: "https://www.microsoft.com/" },
  { name: "nutanix", image: "nutanix.png", link: "https://www.nutanix.com/" },
  { name: "oracle", image: "oracle.png", link: "https://www.oracle.com/in/index.html" },
  { name: "sap", image: "sap.png", link: "https://www.sap.com/india/index.html" },
  { name: "tableau", image: "tableau.png", link: "https://www.tableau.com/" },
  { name: "visa", image: "visa.png", link: "https://www.visa.com/" },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(newsponsors);

function Sponsor() {
  const rows = [["POWERED BY"]];

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className='section section-with-space'
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className='text-center text-main'>
                <b>PARTNERS INCLUDE</b>
              </h1>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row>
              {newsponsors.map((s) => (
                <Col lg={s.grid || 3} className={"ml-auto mr-auto pb-3"}>
                  <a href={s.link}>
                    <Card
                      className='mx-auto'
                      style={{ borderRadius: 0, maxWidth: s.size }}
                    >
                      <img
                        src={require(`assets/sponsors/${s.image}`)}
                        alt='Sponsor logo'
                        width='100%'
                      />
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
