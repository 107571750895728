import React from "react";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import NewsAll from "components/NewsAll";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <NewsAll />
    
      <Footer />
    </>
  );
}

export default RegisterPage;
