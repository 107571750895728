import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";

import Organizer from "componentsCX/Organizer";
import ContactUs from "componentsCX/ContactUs";

import Footer from "componentsCX/Footer.js";
import { Redirect } from "react-router";

function Overview({ match }) {
  const { type } = match.params;
  console.log(type);
  if (
    !type ||
    !["show-information", "the-organiser", "contact-us"].includes(type)
  ) {
    return <Redirect to="/overview/show-information" />;
  }
  let title = "";

  if (type === "the-organiser") {
    title = "THE ORGANIZERS";
  }
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "the-organiser" && <Organizer />}
        {type === "contact-us" && <ContactUs />}
      </div>
      <Footer />
    </>
  );
}

export default Overview;
