export const speakers = [
  {
    name: "Sebastien Borget",
    title: "Co-Founder",
    company: "Sandbox Metaverse Platform",
    image: "Headshot - Sebastien BORGET 2022 (1).jpg",
    text: [
      `Sébastien Borget is the Co-founder and COO of The Sandbox decentralized Metaverse, a virtual
    world where players can create, play, own, govern and monetize their experiences using NFTs &amp;
    SAND, the main utility token of the platform.  Sebastien also became the President of the
    Blockchain Game Alliance in 2020, a non-profit organization of 300 key members of the industry.
    He was recently named #4 in the Top #100 2022 most influential people in crypto by
    CoinTelegraph https://cointelegraph.com/top-people-in-crypto-and-blockchain/sebastien-borget.
    The Sandbox has been featured in TIME100 Top 100 most influential companies of 2022 by Times
    magazine https://time.com/collection/time100-companies-2022/6159401/the-sandbox/`,
    ],
  },
  {
    name: "Dr. Marwan Al Zarouni",
    title: "Director of Information Services",
    company: "Dubai Electronic Security Center, a Digital Dubai Entity.",
    image: "Dr. Marwan Al Zarouni Pic.jpeg",
    text: [
      `Dr. Marwan Alzarouni is one of the top 100 most influential people in blockchain and cryptocurrencies.
    Dr. Alzarouni was a member of the World Economic Forum’s Global Future Council on Cryptocurrencies
    in 2021. He is the CEO of Dubai Blockchain Center, a strategic partner to Dubai Future Foundation.<br/><br/>
    Dr. Marwan is currently the Director of information Services at Dubai Electronic Security Center and a
    member of Dubai Future Council for Blockchain and is the lead for the Digital Asset Task Force (DATF)..<br/><br/>
    Dr. Alzarouni is also a member of Digital Dubai’s Artificial Intelligence (AI) Ethics Committee and the
    Emerging Technology Committee.`,
    ],
  },
  // {
  //   name: "Saqr Draqat",
  //   title: "Co-Founder / Managing Partner",
  //   company: "Crypto Oasis",
  //   image: "placeholder.png",
  //   text: [``],
  // },
  {
    name: "Sohail Nawaz",
    title: "Chief Digital Officer",
    company: "Khaleej Times",
    image: "Sohail Nawaz pic.jpg",
    text: [
      `With an impeccable track record of over 25 years in the media industry, Sohail
    started his career working for one of the largest global brands of its time; Yellow
    Pages. This opened the digital doors of some of the biggest brands in the UK and
    the Middle East to him.<br/><br/>
    Apart from executing data centralization projects for notable brands like Zulekha
    Hospital and ESOL Education, in 2019, Sohail began his exciting stint as Head of
    Digital at Khaleej Times. He not only led the launch of a brand-new website and
    app but also brought in innovative technologies and a 3P strategy (Privacy,
    Prediction, and Personalization) to fulfil the publication&#39;s long-term digital vision.<br/><br/>
    Today, he is the Chief Digital Officer of KT and is well on his way to leading
    Khaleej Times in the Web3 space through its partnership with Sandbox. Under his
    leadership, Khaleej Times will succeed in being the first news publication in Dubai
    to achieve this milestone and to fully embrace a revolutionary digital
    transformation.`,
    ],
  },
  {
    name: "Luca Lamberti",
    title: "Chief Executive Officer",
    company: "99STARZ",
    image: "Luca 99starz.jpeg",
    text: [
      `Coming from a background in the automotive and motorcycle racing industries, Luca is a seasoned
    expert in the aspect of entertainment with a passion for racing. He has since changed his profile and
    become a well-connected investor and advisor in several prominent crypto and blockchain projects as
    well as a trading professional and manager of large NFT portfolios. His experience as a sales and racing
    executive, as well as a financial and change management advisor, has made him a knowledgeable
    professional with a unique knowledge of finance. He is a firm believer in NFT technology and the Open
    Metaverse, which makes him a strict yet passionate leader in the industry.`,
    ],
  },
  {
    name: "Hervé Larren",
    title: "Founder and CEO ",
    company: "Airvey.io",
    image: "Herve Pic.jpg",
    text: [
      `Hervé Larren is Founder and CEO of Airvey Metaverse LLC, an enterprise that builds, supports and
    advises companies throughout the crypto world. Sitting at the epicenter of tokenization and Metaverse,
    Mr. Larren helps organizations transition to Web 3.0 and capture this USD 1 trillion a year opportunity.
  <br/><br/>
    A bitcoin and blockchain adopter since 2013, Mr. Larren became one of the first crypto miners in the US
    before listing his coins in US public vehicles. He currently advises over a dozen projects including: layer 1
    protocols, Web 3 developers, gaming companies and Metaverse builders.<br/><br/>
    He is also the owner of Airvey Gallery and was one of the few collectors approved by Christie’s for the
    $69 million Beeple auction.`,
    ],
  },
  {
    name: "Ramana Kumar",
    title: "Chief Executive Officer",
    company: "Magnati",
    image: "Speaker 1 - Ramana Kumar, CEO, Magnati.jpg",
    text: [
      `Ramana Kumar has been instrumental in driving innovation in the payments space in the region, having led many “first in the market” initiatives across payments, including digital payments, cashless initiatives and the metaverse. Under his leadership, Magnati has become the market leader in launching new generation payment solutions to meet merchant and customer needs.
    `,
    ],
  },

  {
    name: "Umer Farooq ",
    title: "MD & Head of Payment Platform",
    company: "Magnati",
    image: "Speaker 2 -Umer Farooq.jpg",
    text: [
      `Umer Farooq is a customer-centric, seasoned payments industry professional with over 15 years of experience. As a business strategist, he has delivered several initiatives across merchant acquiring and cards businesses. His core strengths include proven skills in payments technology and an ability to innovate while ensuring a secure and seamless customer experience.
    `,
    ],
  },
  {
    name: "Kokila Alagh",
    title: "Founder",
    company: "KARM Legal Consultants",
    image: "Kokila pic.jpg",
    text: [
      `Kokila is the Founder of KARM Legal Consultants and has 17 years of experience as a
      Corporate/Commercial and technology lawyer in UAE. She has a significant experience in investments,
      incorporations, joint ventures, project finance &amp; private equity transactions. She has actively advised on
      acquisition finance, project finance transaction, corporate restructuring, real estate finance and asset
      finance transactions. At KARM, she has provided legal advice to clients providing highly specialized
      technology offering including Blockchain, Open Banking, MedTech and InsureTech focused entities.
      Additionally, she has also advised clients on Security Token Offerings, Artificial Intelligence, Crypto-
      currency exchanges, Digital Wallets and deployment of FinTech services globally.
    `,
    ],
  },
  {
    name: "Leila Hurstel ",
    title: "Chief Metaverse Officer",
    company: "VerseEstate",
    image: "Leila.jpg",
    text: [
      `Chief Metaverse Officer at Verse Estate, hyper realistic metaverse headquartered in Dubai. 
      Founder of the AllStarsWomen DAO, initiative to help close the gender gap in the tech space and Web3 industry. 
      Mentor at Brinc /Animoca Brands accelerator program empowering game changers in web3. 
    `,
    ],
  },
  {
    name: "Faisal Zaidi",
    title: "Co-Founder and CMO",
    company: "Crypto Oasis",
    image: "Faisal.jpeg",
    text: [
      `Faisal is an award-winning chartered marketing professional, having won
    the best marketing initiative of the year at the CC Awards consecutively for
    the years 2015 and 2016. He has more than 15 years of experience in the
    marketing of B2B and B2C products working mostly in the real estate, big
    data, research, construction and manufacturing sectors and has
    undertaken positions in Germany, South Africa, Qatar and the UAE. He has
    also worked as an independent writer and consultant on marketing
    projects in the FMCG, Medical, Fashion and Hospitality sectors.
    Earlier on in his career he was involved in landmark projects like Burj
    Khalifa, the New Hamad international airport and Dubai Metro helping
    market and sell products from the American and European market.
    Graduating from the London School of Economics with a BSc Hons in
    business and marketing and completing my Masters, from the EBS
    Universität in Germany and the University of Stellenbosch in Cape Town
    where he majored in Marketing and Consumer Psychology.
    He has played a strategic role and lead in the creation of the partnership
    data partnership with the Dubai Land Department. As a result he launched
    Mo’asher, the first official house price index for the Emirate of Dubai and
    continued writing it for 3 years. In 2020, Dubai climbed up three places in
    JLL’s Global Real Estate Transparency Index and further strengthened its
    position as MENA’s most transparent real estate market. He wrote,
    designed and published Mo’asher on behalf of the government every
    month. I am currently also a member of the Chartered Institute of
    
    Marketing.`,
    ],
  },

  {
    name: "Mehdi Cherif",
    title: "CEO",
    company: "Pulse",
    image: "photo_2022-05-05 06.47.01.jpeg",
    text: [
      `Mehdi Cherif is a Dubai-based entrepreneur and early metaverse champion with a passion for Tech, Media and Arts. He has a successful record across Emerging and FinTech markets, Music Technologies, Intellectual Properties, Sound Branding and Content Management.<br/><br/>
    He is the Founder and now CEO of Pulse, a revolutionary social music discovery platform that unites and engages artists, users and brands. Thanks to his work at Pulse, Cherif is a key part of the blockchain and metaverse revolution in the Middle East and was recently hand-picked as a speaker at a Binance event. <br/><br/>
    Pulse is just the latest chapter in Mehdi’s story. He worked through the ranks at Virgin UAE to become the Senior Regional international music buyer. He then launched groundbreaking B2B music streaming technology GrooveMasterME in 2012, working alongside Nike, Air Jordan, Under Armour and many more. <br/><br/>
    Alongside that, Mehdi has also consulted for key brands in the region including the beIN Media Group, Samsung and GT Bank.`,
    ],
  },
  {
    name: "Renata Liuzzi",
    title: " Founder & CEO",
    company: "Axis Partners ",
    image: "Renata Liuzzi Pic.jpg",
    text: [
      `A global business-transformation strategist, Renata has a proven track record with blue chip
    consultancies. 17+ years of experience in Business Strategy, Customer Experience, Innovation
    and Digital Transformation working for numerous Fortune 500 companies.<br/><br/>
    International MBA from IE Business School, Mechanical Engineer (with masters in Motorsports),
    Post-Diploma in Design-Thinking, Innovation and Strategy (MIT, Columbia). With certifications
    in Lean Six Sigma, Artificial Intelligence (MIT) and Operational Efficiency (APICS).<br/><br/>
    Renata has successfully lead the delivery of complex transformation programmes, working with
    teams spread across more than 15 countries and doing business with more than 20 countries.
    She has led Customer Experience / Digital departments companies like Emaar Group, Dubai
    holding, and Meraas Holding.<br/><br/>
    She founded Axis Partners to respond to Strategy, CX and innovation challenges, a critical
    component of business survival and evolution post-pandemic. Engaged as a strategic advisor to
    major sovereign investment organisations, major retail and real estate groups in MENA and
    Europe, Renata successfully delivers transformation projects, turning crisis to opportunities.
    She is a thought leader and for that reason, she has been appointed a visiting Lecturer at the IE
    Business School, where she teaches Strategy, CX and Design thinking to International MBA
    Students. She has been also appointed as a Mentor for top start-ups coming from the best
    innovation Lab in Europe (IE-VentureLab).<br/><br/>
    Renata was awarded in 2022 as the second most influential CX/Innovation practitioner
    worldwide by the CX Magazine and featured as one of the top CX/innovation thought-leaders in
    2021.<br/><br/>
    She was recently featured in the front page of the Retail Magazine, where she discusses the
    future of CX with the impact of NFT, Metaverse and Blockchain.`,
    ],
  },
  {
    name: "Patrick Moritz",
    title: "Founder",
    company: "Dubai Peeps",
    image: "patrick pic.jpg",
    text: [
      `Patrick Moritz, also known as PRM, is the founder of ThePeepsProject/DubaiPeeps. He has been living in
    Dubai for the past 14 years and was originally born and raised in Berlin, Germany.
    His entrepreneurial background has made him establish various businesses in the F&amp;B sector and
    consumer product industry. He is a professional sculptor and digital artist and has dedicated his time to
    blockchain technology and the advantages of the digital space in the most recent years. It is his mission
    to encourage mass adoption through connecting the physical with the digital space in the most
    effortless ways possible.`,
    ],
  },
  {
    name: "Jane Thomason",
    title: "Section Chief Editor",
    company: "Journal of Metaverse",
    image: "Jane pic.jpg",
    text: [
      `Author and thought leader on Blockchain, Crypto, Metaverse,  Healthtech and Sustainability. She
    is the Section Chief Editor: Frontiers in Blockchain, and Editorial Board of the Journal of
    Metaverse and Global Health Journal. She is frequently invited to speak at international
    conferences on Future Technologies. She has authored  &quot;Blockchain for Global Social Change&quot;  
    and Applied Ethics in a Digital  World. and has been featured in multiple awards including: Top 100
    Women of the Future (2022); Top 100 Women in Crypto (2020); Women in GovTech (2019) and 
    Top 100 Fintech Influencers for SDGs (2018). She is a Thinkers 360 in the Top 10 Global Thought
    Leader and Influencer on HealthTech Sustainability,  and Crypto.`,
    ],
  },
  {
    name: "Khawja Musfir Zahoor",
    title: "Co-Founder",
    company: "nftONE",
    image: "Khwaja Musfir Pic.jpg",
    text: [
      `Musfir is the co-founder of nftONE.me, a Dubai based NFT marketplace that connects the world to
    middle east based authentic digital assets.
    He has been actively supporting the local blockchain and crypto industry by investing and building
    crypto projects, delivering keynotes and digital assets awareness talks for various industries in the UAE.
    In addition to his role at nftONE, he owns and leads ZuperAds - a leading ad targeting business that
    provides solutions for some of the region’s most well- known brands.
    Before building ZuperAds into what it is today, Musfir worked at senior levels for PropertyFinder.ae,
    AMEinfo.com and other businesses in the UAE.`,
    ],
  },
  {
    name: "Gaurav Dubey",
    title: "Chief Executive Officer",
    company: "TdeF",
    image: "gaurav pic.jpg",
    text: [
      `Mr. Gaurav Dubey is the Chief Executive Officer at TDeFi, incubating and advising DeFi, NFT, Gaming and
    crypto projects. Gaurav has been an evangelist, investor &amp; advisor in the crypto &amp; blockchain space
    since early 2015. Gaurav is onboard as Director, Partner, Ambassador, and advisor to more than 50
    Blockchain and 8 other companies covering different tech and non-tech verticals. He has founded
    projects including InfoTechHub (Deep Tech Incubator), O1EX (Family Office &amp; Crypto Fund), BrainA.live
    (Electronics, EEG and Bio-response analytics start-up) amongst others.`,
    ],
  },
  {
    name: "Maria Vovchok",
    title: "Ambassador",
    company: "Blockchain Association of Ukraine",
    image: "Maria Vov.jpg",
    text: [
      `Maria Vovchok is a journalist with over ten years of experience as a news
    presenter and editor. She has also been a Blockchain enthusiast for more
    than five years, working as a news editor and anchor in several Blockchain
    
    media, and later - for Blockchain companies in marketing and pr-
    departments.<br/><br/> She has also built strong relations with VCs, angel investors,
    
    and startup entrepreneurs.
    Maria is a moderator and a host at multiple IT- and Blockchain conferences.
    She is an Ambassador of the Blockchain Association of Ukraine, a member
    of the international organization ”Blockchain Ladies,” and ”Women on
    Tech."<br/><br/>
    Recently, Maria has been a Managing Director at the World of Web3
    Summit in Dubai, UAE. Currently, she co-organizes top-notch Metaverse and
    Blockchain conferences and advises Blockchain companies expanding in
    the Middle East region.`,
    ],
  },
  // {
  //   name: "Musab Obaid AlHammadi",
  //   title: "Project Manager",
  //   company: "AI Office under the Prime’s Minister Office – Research and Infrastructure Section.",
  //   image: "Musab Ministry of AI.jpg",
  //   text: [``],
  // },
  {
    name: "Melanie Mohr",
    title: "Chief Executive Officer & Founder",
    company: "WOM Protocol ",
    image: "Melanie.jpg",
    text: [``],
  },
];

export const pastspeakers = [];

export const sponsors = [
  {
    name: "Galadari Brothers",
    pageLink: "Galadari Brothers",
    type: "LEAD SPONSOR",
    image: "galadari.jpg",
    text: [``],
    link: "https://www.galadarigroup.com/",
    size: "100%",
    grid: 4,
  },
  {
    name: "DUBAI BLOCK CHAIN CENTER",
    pageLink: "DUBAI-BLOCK-CHAIN-CENTER",
    type: "SUPPORTED BY",
    image: "dubaiblockchain.png",
    text: [``],
    link: "https://blockchaincenter.ae/",
    size: "100%",
    grid: 4,
  },
  {
    name: "REDINGTON",
    pageLink: "REDINGTON",
    type: "STRATEGIC PARTNER",
    image: "redington.jpg",
    text: [
      `Redington Value is the Value-Added Distribution division of Redington Gulf, the largest distributor of
    IT products in Middle East and Africa. The business model is purely channel oriented and
    empowering channel partners with technology through trainings and joint engagement is at the
    core. These solutions span across technology domains such as Networking, Voice, Servers, Storage,
    Software, Security and Infrastructure and emerging technology brackets such as Hyper convergence,
    Cloud Computing and Big Data Analytics.<br/><br/>
    Redington Gulf is also uniquely positioned to help customers leverage mixed reality solutions to
    provide innovative offerings for end customers.<br/><br/>
    Redington continues to be a pioneer in understanding, helping, and assisting partners and customers
    to digitally transform themselves by leveraging Cloud technologies. With over 65 vendor
    relationships in various parts of Middle East and Africa, we ensure that all the requirements of our
    channel partners are met under one-roof.`,
    ],
    link: "https://redingtongroup.com",
    size: "100%",
    grid: 4,
  },
  {
    name: "Magnati",
    pageLink: "Magnati",
    type: "STRATEGIC PARTNER",
    image: "magnati.jpg",
    text: [
      `Magnati MetaV is driving the next generation of e-commerce – experiential commerce – as the region’s first metaverse marketplace that enables users to experience e-commerce in a new, immersive way. The digital platform provides users with richer visual and sensory information to shop, learn, play games, attend concerts and more through a seamless online experience. The metaverse’s multi-dimensionality enables consumers to be immersed in a marketplace where they can see and feel retail offerings before they purchase. 
<br/><br/>
    By providing merchants with tools that enable customers to virtually experience a product, Magnati MetaV helps to address commonly faced e-commerce challenges, thereby reducing sales cancellations, returns and exchanges. The platform also enables new business growth and revenue opportunities for many other merchant segments, including government, education, events and gaming.
    <br/><br/>
    Magnati MetaV is the first metaverse platform fully integrated with traditional payment rails and gateways to facilitate the transition from electronic commerce to experiential commerce.
    
    `,
    ],
    link: "https://www.magnati.com/",
    size: "100%",
    grid: 4,
  },
  {
    name: "Crypto oasis",
    pageLink: "Crypto oasis",
    type: "ECOSYSTEM PARTNER",
    image: "crypto.jpg",
    text: [``],
    link: "https://cryptooasis.ae/",
    size: "100%",
    grid: 4,
  },
  {
    name: "Kalandoor Group",
    pageLink: "Kalandoor",
    type: "NETWORKING PARTNER",
    image: "kbc.jpeg",
    text: [],
    link: "https://kalandoor.com/",
    size: "100%",
    grid: 4,
  },
];
const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    title: "Khaleej Times launches CX Evolve 2022 summit",
    link: "https://www.pressreader.com/uae/khaleej-times/20220709/281943136591658",
    image: "Logo-cx.png",
  },
];
