// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";
import Slider from "react-slick";
import React from "react";

function LandingPageHeader({ home }) {
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section py-0"
        style={{
          padding: "8rem 0 5rem",
          marginTop: "4rem",
          background: "#fff",
        }}
        data-parallax={true}
      >
        <img src={require("assets/images/Asset27mobile.jpg")} alt="hero" width="100%" />
        <Container className="pt-3">
          <Row>
            <Col lg={12} className="text-center">
              <img
                src={require(`assets/images/logo/metalogo.png`)}
                style={{ maxWidth: "200px" }}
                width="100%"
                alt="CX"
              />
              <br />
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0 "
                      color="primary"
                      style={{
                        width: "99%",
                        background:
                          "linear-gradient(97deg, rgba(218,0,126,1) 32%, rgba(79,37,131,1) 93%)",
                        border: "0",
                      }}
                      href="/meta-vision2022/register"
                    >
                      <p className="m-0  text-900-cx " style={{ color: "#fff" }}>
                        REGISTER <br /> NOW
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0 "
                      color="primary"
                      style={{
                        width: "99%",
                        background:
                          "linear-gradient(97deg, rgba(218,0,126,1) 32%, rgba(79,37,131,1) 93%)",
                        border: "0",
                      }}
                      href="/meta-vision2022/brochure"
                    >
                      <p className="m-0 text-900-cx" style={{ color: "#fff" }}>
                        DOWNLOAD <br />
                        BROCHURE
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
