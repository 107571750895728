import React from "react";

import Footer from "componentsCX/Footer.js";
import NavbarDiv from "componentsCX/Navbar";
import NewsAll from "componentsCX/NewsAll";
import { news } from "./content";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <ProfilePageHeader
        title='Industry News'
        bgColor='#fff'
      />
      <NewsAll news={news} />

      <Footer />
    </>
  );
}

export default RegisterPage;
