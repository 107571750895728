import React from "react";

import LandingPageHeader from "componentsWebinar/Headers/LandingPageHeader.js";

import Footer from "componentsWebinar/Footer.js";
function RegisterPage() {
  return (
    <>
      <LandingPageHeader />
      <Footer />
    </>
  );
}

export default RegisterPage;
