export const speakers = [
  {
    name: 'Samah Mohamed Al Hajeri',
    title: 'Director of Cooperatives & Strategic Reserve',
    company: 'Ministry of Economy',
    image: 'Samah Pic.jpg',
    text: [],
  },
  {
    name: 'Margaret Ann Neale',
    title: 'Co-Director, Executive Program in Women’s Leadership',
    company: 'Standford Business',
    image: 'Prof Maggie pic.jpg',
    text: [],
  },
  {
    name: 'Ebru Tuygun',
    title: 'Regional CMO',
    company: 'Accenture Middle East',
    image: 'ebru.jpg',
    text: [
      `Ebru studied Political Science and International Relations at Boğaziçi University. She has Management Excellence and Leadership Excellence certificates from Harvard Business School. She was recently part of ‘High Potential Women Leaders’ program of Stanford Graduate School of Business. Ebru led Deloitte’s Corporate Marketing and Communications department in Turkey for 7 years and in 2012, joined Accenture to establish Corporate Marketing and Communications department in Turkey. She conducted flagship brand programs and led Diversity & Inclusion agenda. Broad range of her skills and expertise include Reputation Management, Strategic Marketing and Communications, Employee Engagement, Digital Marketing, Brand Strategy, and Account Based Marketing leading to business impact and driving brand awareness. In 2015, she became Regional CMO for Accenture Digital and Accenture Technology in the Middle East, Africa, Russia and Turkey. In 2017, she became the CMO for Accenture Middle East and Turkey regions. She is one of the founding members of “Women Board of Directors” in Turkey. She enabled WEConnect International Turkey Chapter – gathering women entrepreneurs with international firms, had activated the support by Accenture. She participated in Cannes Lions Creative Festival’s CMO Accelerator Program for three years consecutively since 2015; her articles on this program have been published in Campaign and Digital Age magazines. Ebru is a member of various women business associations, supports Women20 (W20) and next generation women leaders. She is selected among Top50 CMOs in Turkey, Middle East’s Women Leaders by World Women Leadership Congress and Women in Martech Vibe Awards. Ebru is married and lives in Dubai.`,
    ],
  },
  {
    name: 'Lynn Al Khatib',
    title: 'Group Head of Communication',
    company: 'Chalhoub Group',
    image: 'Lynn AlKhatib (1).jpg',
    text: [
      `Lynn Al Khatib is Group Head of Communication at
Chalhoub Group, the largest retailer and distributor of
luxury brands in the Middle East and North Africa. She
brings over 15 years of corporate and brand communication experience to her current role,
which includes building the Group’s corporate brand and communication strategy, managing
social media channels, keeping team members informed through internal communications,
crisis management, as well as managing external communications and stakeholder
relationships.`,
      `Lynn joined Chalhoub Group in 2021 after more than two years as the Global Nutrition, Heath,
and Wellness Communication Manager at Nestlé S.A, where she worked from the world’s
leading food and beverage company’s headquarters in Switzerland on bridging corporate,
scientific, and consumer information. She particularly led the development of a modular
system that allows brands to communicate their purpose and impact in society.
Lynn holds a Master-Degree in Nutrition and a Bachelor of Science Degree in Nutrition and
Dietetics from the American University of Beirut.`,
      `A proud mother of two boys, Lynn’s personal passion is to help people lead healthier and more
active lives.`,
    ],
  },
  {
    name: 'Fariha Salahuddin',
    title: 'SVP, CHRO',
    company: 'PepsiCo',
    image: 'FS.jpg',
    text: [
      `Fariha brings more than 25 years of global HR leadership experience to PepsiCo, working across
      emerging and developed markets in several industries. Most recently, she served as General Manager,
      HR for ICI Pakistan Limited. Prior to that, she spent 10 years with GlaxoSmithKline, where she held
      several regional and global roles, including VP, Global Talent Business Leader, Consumer Healthcare,
      based in the U.K. Fariha has also held HR leadership roles in Mölnlycke, Citibank, Unilever, and ABN
      AMRO in Sweden, Turkey, Singapore, the U.K., and Pakistan. Additionally, she is a certified professional
      coach from the iPEC coaching institute.`,
    ],
  },
  {
    name: 'HE Shamsa Saleh',
    title: 'CEO',
    company: 'Dubai Women Establishment and Secretary-General of the UAE Gender Balance Council',
    image: 'Shamsa_Saleh (1).jpg',
    text: [
      `HE Shamsa Saleh began her career with Dubai Women Establishment
      (DWE) in 2008, rising to the position of Director of Strategic
      Development & Corporate Development, followed by Chief Executive
      Officer in 2011. HE is also Secretary-General of the UAE Gender
      Balance Council, which was established to support the UAE’s vision of
      becoming a regional and international leader in gender equality.<br/><br/>
      Under her leadership, DWE has grown from strength-to-strength,
      launching several key projects and initiatives which support the
      Establishment's mission to enhance the crucial role of UAE women as
      contributors to their society. These include Global Women’s Forum
      Dubai, the first Women’s Forum for the Economy & Society event to be
      held in the MENA region, the Arab Women Leadership Forum, Women
      in Boards Initiative, Women Leadership Exchange Program, Emirates
      Leaders Gathering and the National Child Care Centers Project, amongst
      many more.<br/><br/>
      Prior to joining DWE, HE Shamsa Saleh served as Head of Corporate
      Planning & Projects Management – Vice President Corporate Strategy at
      Dubai International Financial Exchange (DIFX) from 2006 to 2008. HE
      also worked as Strategy and Special Project Senior Analyst, as well as
      Acting Manager in the strategy department at TECOM, from 2002 to
      2006.`,
    ],
  },

  

  
];

export const pastspeakers = [];

export const sponsors = [
  {
    name: 'Betty Crockers ',
    pageLink: 'Betty Crockers ',
    type: 'IFTAAR SPONSOR',
    image: 'bettycrocker.jpg',
    text: [],
    link: 'https://www.bettycrocker.com/',
    size: '100%',
    grid: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy('type');
export const sponsorsPage = groupByType(sponsors);

export const news = [];
