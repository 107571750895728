import React from 'react';

import { Container, Row, Col, Card } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

const content = [
  { title: 'Diversity Practitioners', image: '1.png' },
  { title: 'Chief Diversity Officers, VPs, Directors, and Managers', image: '2.png' },
  { title: 'VP’s, Directors and Managers of HR', image: '3.png' },
  { title: 'Corporate Diversity Council Members', image: '4.png' },
  { title: 'People Managers, Line Managers and Business Unit Leaders', image: '5.png' },
  { title: 'Diversity Change Management Agents', image: '6.png' },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: 'white' }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="title mb-2 section-title text-center" style={{ color: '#624332' }}>
                <b>UAE FEMALE DIPLOMATIC REPRESENTATION</b>
              </h1>
              <br />
            </Col>
            <Col lg={9}>
              <h3 className="text-400 text-dark text-center mb-3">
                Women make up 30% of the diplomatic corps, where they occupy 234 positions, 42
                working on missions abroad, and 7 being ambassadors. The UAE has started appointing
                women as ambassadors ever since 2009. Up to date the UAE has 7 female ambassadors:
              </h3>
            </Col>
            {content.map((data, index) => (
              <Col lg={4} key={index} className="py-2 ">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <Card style={{ background: 'transparent', boxShadow: 'none' }} className="mx-auto">
                    <img
                      src={require(`assets/images/womenrep/${data.image}`)}
                      alt=""
                      width="100%"
                      // style={{ maxWidth: "75px" }}
                    />
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
