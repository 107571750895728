import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Sponsor({ sponsors }) {
  const rows = [
    ["TECHNOLOGY PARTNER"],
    ["STRATEGIC SPONSOR"],
    ["GOLD SPONSOR"],
    ["SILVER SPONSOR"],
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "#201e45",

          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>2022 SPONSORS</b>
              </h1>
            </Col>
            <Col lg={12}>
              <Container>
                {rows.map((row) => (
                  <Row>
                    {row.map((sponsor) =>
                      sponsors[sponsor].map((s) => (
                        <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <h3
                              className="text-white text-400 text-uppercase text-center pb-3"
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingTop: s.title && "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: !s.title ? s.type : "",
                              }}
                            ></h3>
                            <a href={s.link}>
                              <Card
                                className="mx-auto"
                                style={{ borderRadius: 0, maxWidth: s.size }}
                              >
                                <img
                                  src={require(`assets/sponsors/${s.image}`)}
                                  alt={s.name}
                                  width="100%"
                                  className="rounded-0"
                                />
                              </Card>
                            </a>
                          </ScrollAnimation>
                        </Col>
                      ))
                    )}
                  </Row>
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
