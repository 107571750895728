import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
function TextGrid() {
  let pageHeader = React.createRef();

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          // backgroundColor: "#fff",
          padding: "0rem 0 6rem 0",
          backgroundImage: "url(" + require("assets/images/Asset25.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container
          fluid
          className="align-self-start"
          style={{ backdropFilter: "blur(8px)", paddingTop: "6rem" }}
        >
          <Row className="justify-content-center">

            <Col lg={4}>
              <h1
                className="pt-3 text-meta-g meta-title"
                // style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                One-day event with thought leadership speeches, interactive sessions, panel
                discussions, AR/VR Installations and more
              </h1>
            </Col>
            
            <Col lg={4}>
              <h1
                className=" section-title-cx pt-3 text-meta-g meta-title"
                style={{ fontSize: size ? "2rem" : "2rem" }}
              >
                <b>Venue and Date</b>
              </h1>
              <h2 className="text-700-cx text-meta-g meta-title">
                DUBAI, UAE
                <br />
                21 SEPTEMBER 2022
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
