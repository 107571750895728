import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import Register from "componentsCX/Register";
import Footer from "componentsCX/Footer.js";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="REGISTER NOW" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="REGISTER" />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
