import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
function ContactUs() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        className="section "
        style={{
          background: '#fbfbfb',
          alignItems: 'center',
          paddingTop: size ? '8rem' : '3rem',
        }}
      >
        <Container>
          <Row>
            <Col lg={12}>
            <h3 className="text-400 pt-3">For all general enquiries contact: </h3>
            </Col>
            {content.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <Card className="p-3 text-center">
                  <h3 className="text-700 pt-3">{data.title}</h3>
                  <div>
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{' '}
                      <a href={`mailto:${data.link}`}>{data.link}</a>
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}
const content = [
  { title: 'Poonam Chawla', link: 'poonam@khaleejtimes.com' },
];

export default ContactUs;
