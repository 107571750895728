import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      className="footer pt-2 py-3"
      style={{
        backgroundColor: "#ec7026",
        // backgroundImage: "linear-gradient(to bottom, #fff, #e2f2ff)",
      }}
    >
      <Container fluid>
        <Row className="justify-content-center py-2">
          <Col lg={4} className="py-2 text-center text-white">
            <h3>Organised By</h3>
            <div className=" text-center">
              <a
                href="https://www.khaleejtimes.com/"
                className="text-center pr-2"
              >
                <img
                  src={require(`assets/images/logo/khaleej1.png`)}
                  style={{ maxWidth: "300px" }}
                  width="100%"
                  alt="Finnovex"
                />
              </a>
              {/* <a href="https://www.jpost.com/">
                <img
                  src={require(`assets/images/logo/jerusalem.png`)}
                  style={{ maxWidth: "300px" }}
                  width="100%"
                  alt="Finnovex"
                />
              </a> */}
            </div>
          </Col>
          <Col lg={4} className="text-center py-2 align-self-center">
            <h3 className="d-inline">
              <a href="https://www.khaleejtimes.com/privacy-policy" className="text-white text-700-cx">
                Privacy Policy
              </a>
            </h3>
            <span className="text-white mx-3">{"  |  "}</span>
            <h3 className="d-inline">
              <a href="/contact-us" className="text-white text-700-cx">Contact</a>
            </h3>
          </Col>

          <Col lg={4} className="text-center align-self-center">
            <h3 className="mb-0 text-white text-700-cx">Connect With Us</h3>
            {/* <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ color: "#1d1960" }}
              href="https://www.facebook.com/KTRemoteWorkforceSummit/"
            >
              <i className="fa fa-facebook" />
            </Button> */}
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ color: "#1d1960" }}
              href="https://www.linkedin.com/showcase/khaleej-times-events/?viewAsMember=true"
            >
              <i className="fa fa-linkedin" />
            </Button>
            {/* <Button
              className="btn-neutral btn-just-icon"
              style={{ color: "#1d1960" }}
              href="https://twitter.com/IsraelUae"
            >
              <i className="fa fa-twitter" />
            </Button> */}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center text-900-cx text-white"}>
            <p>
              ©2022 Galadari Printing and Publishing LLC. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
