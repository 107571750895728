import React from "react";
import { Container, Row, Col } from "reactstrap";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <Container>
          <div className="owner">
            <div>
              <img
                alt="Portrait of speaker"
                className="mt-3 img-no-padding img-responsive"
                src={require(`assets/speakers/${speaker.image}`)}
                style={{ maxWidth: "120px" }}
              />
            </div>
            <div className="name">
              <h4 className="title" style={{ marginBottom: "0" }}>
                {speaker.name}
              </h4>
              <h6 className="description">{speaker.title}</h6>
              <h6 className="description">{speaker.company}</h6>
              <div>
                {speaker.companyImage && (
                  <img
                    src={require(`assets/speakers/${speaker.companyImage}`)}
                    alt=""
                    width={200}
                  />
                )}
              </div>
              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              {speaker.text.map((t, index) => (
                <>
                  <p
                    style={{ fontSize: "12px" }}
                    key={t}
                    dangerouslySetInnerHTML={{ __html: t }}
                  />
                  <br />
                </>
              ))}
            </Col>
          </Row>
        </Container>
      </>
    );
  } else return "";
}

export default SpeakerModal;
