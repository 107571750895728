import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";
import CardSubtitle from "reactstrap/lib/CardSubtitle";

import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Coming Up</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {comingup.map((c) => (
              <Col lg={3}>
                <a href={c.link} target="_blank" without rel="noopener noreferrer">
                  <Card>
                    <CardImg
                      alt={c.title}
                      src={require(`assets/editions/${c.image}`)}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h2">{c.title}</CardTitle>
                      {/* <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                        {c.date}
                      </CardSubtitle> */}
                      {/* <CardText className='text-400'>{c.description}</CardText> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Recent</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {recent.map((c) => (
              <Col lg={4}>
                <a href={c.link} target="_blank" without rel="noopener noreferrer">
                  <Card>
                    <CardImg
                      alt={c.title}
                      src={require(`assets/editions/${c.image}`)}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h2">{c.title}</CardTitle>
                      {/* <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                        {c.date}
                      </CardSubtitle> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
            <Col lg={12}>
              <Button className="btn" color="primary" outline size="md" href="/recent">
                Load More
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const comingup = [
  {
    title: "UniExpo",
    date: "March 2022",
    description: "",
    link: "https://www.ktuniexpo.com/",
    image: "uniexpo.png",
  },
  {
    title: "KT Desert Drive 2022",
    date: "February 2022",
    description:
      "The love for the desert is something that is in common for the UAE citizens and expats alike. The KT Desert Drive experience is a must-do in Dubai, it is NOT a desert safari where you sit as a passenger at the mercy of a driver.",
    link: "https://ktdesertdrive.com/",
    image: "desert.png",
  },
  {
    title: "Middle East - Women Board of Directors",
    date: "April 2022",
    description: "",
    link: "http://khaleejtimesevents.com/women-directors",
    image: "womenMain.jpg",
  },
  {
    title: "The Journey To NET ZERO",
    date: "November 2022",
    description: "",
    link: "https://ktnetzero.khaleejtimesevents.com/",
    image: "ktnetzero.jpg",
  },
];

export const recent = [
  {
    title: "Artelligence Forum 2022",
    date: "August 2022",
    description: "",
    link: "https://artelligenceforum.com/",
    image: "artelligence.png",
  },

  {
    title: "Meta Vision 2022",
    date: "22 September 2022",
    description: "",
    link: "http://khaleejtimesevents.com/meta-vision2022",
    image: "metalogo.png",
  },
  {
    title: "DigiBank Forum 2022",
    date: "SEPTEMBER 2022",
    description: "",
    link: "https://ktdigibank.com/",
    image: "digibank.png",
  },
  {
    title: "CX Evolve 2022",
    date: "August 2022",
    description: "",
    link: "http://khaleejtimesevents.com/cx-evolve2022",
    image: "Logo-cx.jpg",
  },
  {
    title: "Middle East Digital Health Forum",
    date: "May 2021",
    image: "dhf.png",
    link: "https://dhf.khaleejtimesevents.com/",
  },
  {
    title: "New Age Finance & Accounting Summit",
    date: "May 2021",
    image: "nafa.png",
    link: "https://newagefinanceexpo.com/",
  },

  {
    title: "India Real Estate Show 2022",
    date: "May 2022",
    description:
      "IRES 2022 will once again assemble some of India's biggest real estate players and provide an opportunity to the UAE's non-resident Indians (NRIs) and other foreign investors in the country to cash in on lucrative onsite deals and financing options.",
    link: "https://indianrealestateshow.com/",
    image: "ires.png",
  },

  {
    title: "Remote Workforce Summit",
    date: "March 2021",
    image: "remote.jpg",
    link: "https://remoteworkforcesummit.com/",
  },
  {
    title: "UAE-INDIA Investment Forum 2022",
    date: "March 2022",
    description: "",
    link: "/uae-india-investment-forum",
    image: "uaeii.png",
  },
  {
    title: "Future of Insurance",
    date: "February 2022",
    image: "foic.png",
    link: "https://futureofinsurancecongress.com/",
  },

  {
    title: "Accenture Exchange",
    date: "February 2022",
    description: "",
    link: "http://khaleejtimesevents.com/accenture-exchange",
    image: "accenture.jpg",
  },
  {
    title: "Changing Landscape of Tax Compliances & Audit frameworks in UAE",
    date: "January 26, 2022",
    description: "",
    link: "/mbg-webinar",
    image: "Webinar.png",
  },
];

export default TextGrid;
