import React from 'react';

import { Container, Row, Col, Card } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

const content = [
  { title: 'Diversity Practitioners', image: '1.png' },
  { title: 'Chief Diversity Officers, VPs, Directors, and Managers', image: '2.png' },
  { title: 'VP’s, Directors and Managers of HR', image: '3.png' },
  { title: 'Corporate Diversity Council Members', image: '4.png' },
  { title: 'People Managers, Line Managers and Business Unit Leaders', image: '5.png' },
  { title: 'Diversity Change Management Agents', image: '6.png' },
];
const conten2 = [
  { title: 'Women leaders from public and private sectors ', image: '7.png' },
  { title: 'C-suite leaders of private sector', image: '8.png' },
  {
    title: 'Government officials and decision makers from leading Women Organizations',
    image: '9.png',
  },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: 'white' }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="title mb-2 section-title text-center" style={{ color: '#624332' }}>
                <b>WHO SHOULD ATTEND</b>
              </h1>
              <br />
            </Col>
            <Col lg={9}>
              <h3 className="text-400 text-dark text-center mb-3">
                Khaleej Times passionately believes in developing outstanding female leaders and
                promoting gender diversity, both at our firm and in the Middle-East at large. We
                invite you to take the next step of your own leadership journey with us through
                Women Board of Directors initiative.
              </h3>
            </Col>
            {content.map((data, index) => (
              <Col lg={4} key={index} className="py-2 ">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <Card
                    style={{ maxWidth: '100px', background: 'transparent' }}
                    className="mx-auto"
                  >
                    <img
                      src={require(`assets/images/Iconsmain/${data.image}`)}
                      alt=""
                      width="100%"
                      // style={{ maxWidth: "75px" }}
                    />
                  </Card>
                  <h3 className="text-600 text-center text-dark">{data.title}</h3>
                </ScrollAnimation>
              </Col>
            ))}
            
          </Row>
          <Row className="mt-4 justify-content-center	">

          {conten2.map((data, index) => (
              <Col lg={6} key={index} className="py-2 ">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <Card style={{ background: 'rgb(248,240,237)' }} className="mx-auto">
                    <div className="d-flex">
                      <img
                        src={require(`assets/images/Iconsmain/${data.image}`)}
                        alt=""
                        width="100%"
                        style={{ maxWidth: "150px" }}
                      />
                      <h2 className="text-600 text-center text-dark align-self-center pl-2">{data.title}</h2>
                    </div>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
