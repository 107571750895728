import React from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: '40px' }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            {/* <NavbarBrand
              data-placement="bottom"
              to="/"
              target="_blank"
              className="p-0"
              tag={Link}
            >
              <img
                src={require(`assets/images/logo/navbarlogo.png`)}
                className=""
                width="100%"
                style={{ maxWidth: "200px" }}
                alt="logo"
              />
            </NavbarBrand> */}
            <button
              aria-expanded={navbarCollapse}
              className={classnames('navbar-toggler text-right navbar-toggler', {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2`}
                    style={{
                      color: scroll ? '#634432' : '#634432',
                      backgroundColor: 'transparent',
                    }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: '1.5rem' }} />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === '/' && link.page === '/' ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-600"
                        style={{ color: scroll ? '#634432' : '#634432' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-600"
                        style={{ color: scroll ? '#634432' : '#634432' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-600"
                        style={{ color: scroll ? '#634432' : '#634432' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom text-600"
                      style={{ color: scroll ? '#634432' : '#634432' }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className="navbar-item-custom text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  { name: 'HOME', link: '/women-directors', page: undefined, items: undefined, grid: 1 },
  {
    name: 'OVERVIEW',
    link: '',
    page: '/',
    items: [
      { name: 'ABOUT', link: '/women-directors/#about' },
      { name: 'THE ORGANIZERS', link: '/women-directors/overview/the-organiser' },
      { name: 'CONTACT US', link: '/women-directors/overview/contact-us' },
    ],
    grid: 1,
  },
  // {
  //   name: 'SPONSOR',
  //   link: '/women-directors/sponsor/sponsorship-enquiry?utm_source=SPONSOR',
  //   page: undefined,
  //   items: [
  //     //   {
  //     //     name: "WHY SPONSOR?",
  //     //     link: "/sponsor/why-sponsor",
  //     //   },
  //     // { name: "WHO ATTENDS?", link: "/sponsor/who-attends" },
  //     // {
  //     //   name: "DOWNLOAD BROCHURE",
  //     //   link: "/sponsor/download-brochure?utm_source=BROCHURE",
  //     // },
  //     {
  //       name: 'SPONSORSHIP ENQUIRY',
  //       link: '/women-directors/sponsor/sponsorship-enquiry?utm_source=SPONSOR',
  //     },
  //     {
  //       name: 'SPONSORS & PARTNERS',
  //       link: '/women-directors/sponsor/sponsors-partners',
  //     },
  //   ],

  //   grid: 1,
  // },
  {
    name: 'SPONSORS',
    link: '/women-directors/#sponsors',
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "AGENDA",
  //   link: "agenda",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "SPEAKERS",
    link: "/women-directors/#speakers",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: 'REGISTER',
    link: '/women-directors/register',
    page: undefined,
    items: undefined,
    grid: 1,
  },
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/khaleejtimes",
  // },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/khaleejtimes/mycompany/',
  },
  // {
  //   name: "twitter",
  //   link: "https://twitter.com/khaleejtimes",
  // },
];
