import React from "react";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import Recent from "components/Recent";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <ProfilePageHeader title="RECENT"/>
      <Recent />

      <Footer />
    </>
  );
}

export default RegisterPage;
