import React from 'react';

import { Container, Row, Col } from 'reactstrap';

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: '#201e45',
        }}
        className="section section-with-space border-top border-bottom pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: '#fff', fontSize: '3rem' }}
              >
                <b>KEY FOCUS AREAS</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content3.map((data, index) => (
              <Col lg={data.grid || 6} xs={12} key={index} className="py-5">
                <Container fluid>
                  <Row>
                    <Col xs={3}>
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        width="100%"
                        style={{ maxWidth: '100px' }}
                        className="mx-auto"
                        alt="icon"
                      />
                    </Col>
                    <Col xs={9}>
                      <h2 className="text-900-cx text-uppercase text-cx-g">{data.title}</h2>
                      <h3 className="text-400-cx text-white">{data.description}</h3>
                      <h3 className="text-400-cx text-white">
                        <ul>{data.points && data.points.map((p) => <li>{p}</li>)}</ul>
                      </h3>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title: 'Employee Training and Empowerment',
    description:
      'Are organizations able to effectively empower the front-end and customer-facing employees to a 360-degree information view- enabling decision making and informed decision making. ',
    image: '56.png',
  },
  {
    title: 'Convergence of Digital and Physical CX',
    description:
      ' The connected customer demands standards across the board-be, in-store or digital. What are the best brands doing to ensure consistency and raise the bar? ',
    image: '57.png',
  },
  {
    title: 'The constant "new normal"',
    description:
      'It is one thing to be in the middle of a pandemic, another to be towards the endemic-perhaps more dynamic to be in the "new normal" which embraces the digital and physical in varying degrees-the permanent hybrid. What would be the critical building blocks for strategy, roadmap and ultimately delivering the value to the unforgiving social media-connected customer?',
    image: '58.png',
  },

  {
    title: 'The Metaverse',
    description: `How does Metaverse fit in CX outlook? A fad or here to stay. 
      How is the Metaverse shaping the future of e-commerce and creating experiences? What are the investments made behind the scenes by the segment's leaders? With growing investments in Metaverse, it is evident that it is already here to stay- whether that bleeds investments for the next 3 to 5 years and then grows is to be seen. 
      `,
    image: '59.png',
  },
  {
    title: 'AI and Human Collaboration',
    description:
      'Investing in AI is the easy part. As we mature in chat-bots technology through the application of Machine Learning and AI, we are also witnessing more excellent AI-enabled automation processes and human agents- leading to greater efficiencies and customer satisfaction. Enabling AI and human touch leads to 61 % better customer happiness, while agent satisfaction is at 69 %, and agent productivity also improves by 66 %, according to Forrester.',
    image: '60.png',
  },
  {
    title: 'Social Media and Customer Experience',
    description:
      'Technology for integrating communication across social media platforms enables a seamless experience for the customer and more significant data points captured for brands to engage the customer further. Assessing Platform integration solutions requires a fluid approach and the pulse on the Metaverse: the more connected world, the more excellent the opportunity and challenge in owning the experience.',
    image: '61.png',
  },
  {
    title: 'E-commerce and WhatsApp Experience',
    description:
      'Are our customers now moving beyond traditional apps to new-age WhatsApp shopping? The WhatsApp age has increased customer expectations regarding response times, resolution and frictionless journeys, requiring a more agile and emphatic customer experience. ',
    image: '62.png',
  },
  {
    title: 'Voice of the customer and insights ',
    description:
      'More deep dive data-driven insights powered by big data and real-time actionable insights create additional opportunities to be proactive and enrich the customer journey. What are the real industry case studies, and evaluate if they can be customized for your specific friction points? ',
    image: '63.png',
  },
  {
    title: 'Customer Experience in the Government sector ',
    description:
      'Service excellence developments in the government sector. The five best performing and worst performing government entities of UAE in 2021 were announced toward "establishing a digital government that keeps pace with global trends." The top five performing UAE Government entities are:',
    points: [
      'Ministry of Interior',
      'Federal Authority for Identity and Citizenship',
      'Ministry of Foreign Affairs & International Cooperation',
      'Ministry of Climate Change and Environment',
      'Ministry of Community Development.',
    ],
    image: '64.png',
    grid: 8,
  },
];

export default WhyAttend;
