import React from "react";
import {
  // Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={6}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#0025c8", minHeight: "130px" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col lg={2} className="align-self-center">
                    <h2 className="text-900 text-white"> {d.time}</h2>
                  </Col>
                  <Col lg={8} className="align-self-center text-white border-left">
                    <h2 className="text-400">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div className="section" data-parallax={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center section-title" style={{ color: "#0025c8" }}>
                  <b>AGENDA</b>
                </h1>
                <h2 className="text-700 text-center mb-4 text-white">
                  Accenture Exchange Opening Ceremony
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <AgendaCard data={content1} />
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "1",
    title: "Experience Vs Risk Mitigation",
    description: `Speaker: Mohammed Muneebuddin, VP BFSI, Cloud4C`,
  },
  {
    time: "2",
    title: "Cloud Transformation for financial services",
    description: `Speaker: Shahid Khan - Industry Strategy LeaderEMEA, Oracle`,
  },
  {
    time: "3",
    title: "Panel Discussion - Future of Financial Services",
  },
  {
    time: "4",
    title: "Q & A",
  },
];

export default Agenda;
