// reactstrap components
import React from "react";

import Register from "componentsWebinar/Register";
import TextGrid from "componentsWebinar/TextGrid";
import Agenda from "componentsWebinar/Agenda";
// import Sponsor from "components/Sponsor";
import SpeakerGrid from "componentsWebinar/SpeakerCard";
import NavbarDiv from "componentsWebinar/Navbar";
import Headers from "./header";

function LandingPageHeader() {
  return (
    <>
      <NavbarDiv />
      <section id="register" />
      <Headers />
      <TextGrid />
      <section id="agenda" />
      <SpeakerGrid />
      <Register type="REGISTERWEBINAR" />
    </>
  );
}

export default LandingPageHeader;
