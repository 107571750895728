import React from "react";

import { Container, Row, Col, Modal, Button } from "reactstrap";
import SpeakerModal from "componentsuaeIndia/SpeakerModal.js";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

export const chairmans = [
  {
    name: "Ali Juma Alajme",
    title: "Director of Smart Health",
    company: "Ministry of Health and Prevention (MOHAP), UAE",
    image: "Ali-Juma-AlAjme.jpeg",
    text: [
      `
  Ali Alajme is the Director of Smart Health in the Ministry of Health &amp; Prevention, UAE.<br/><br/>
He has an Executive MBA from Higher Colleges of Technology. He graduated from
Mohammed Bin Rashid Government Innovation Center in Association with Cambridge
University, UK. Ali Alajme is an alumnus of the Mohammed Bin Rashid leadership
development program in Executive leadership associated with Duke University, and of
Government Experience Exchange Trainers from the UAE prime minister’s office program.<br/><br/>
Having deep knowledge of IT and quality, Ali Alajme worked on big government and national
initiatives such as UAE smart initiative which resulted in bringing UAE to rank six in the
Global Competitiveness Report in Smart Government KPI. He was awarded for providing the
best mobile service at the government summit 2015. In the past, he has worked with many
committees such as the AI data committee, Smart Services committee, Arab Health
Committee, and a federal committee on the adoption of the 4th industry generation.<br/><br/>
Ali Alajme was honoured as a CIO of the year 2020 by IDC/IDG for his work in technology
during the pandemic.
  `,
    ],
  },
  {
    name: "Mehailah Hashel Alzehmi",
    title: "Director, Customer Happiness Department",
    company: "Roads & Transport Authority, Dubai",
    image: "Mehailah+Al+Zahmi.jpeg",
    text: [
      `
      Mehailah Alzehmi is currently the Director of the Customer Happiness Department at the Roads & Transport Authority, Dubai. She is accountable for maintaining effective and efficient service delivery by engaging internal and external customers, taking ownership of customer issues, and deploying strategies to prioritize customer needs and expectations. Mehailah also showcased her leadership as a Digital Media Manager and led the development of the RTA Digital Strategy to enhance RTA's brand image. Before joining RTA, she also provided leadership in the financial sector by implementing the Digital Banking initiative.
      <br/> 
<br/>
Mehailah has been a catalyst for change and demonstrated an in-depth understanding of disruptive business strategies and intuitive discrimination for technological processes to support organizational objectives. She gained recognition for resolving complex business issues and increasing customer happiness by providing services through innovative service delivery channels. Mehailah holds a Bachelor's Degree in IT- network security.

 
  `,
    ],
  },
];
function SpeakerGrid({ speakers }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Container className="mb-3">
          <Row>
            <Col xs={12} className="px-0">
              <div className="card-avatar px-3 pt-3 rounded-circle">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  width="100%"
                  className="rounded-circle"
                  alt="..."
                  style={{
                    borderRadius: 0,
                    maxWidth: "450px",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="text-center">
              <h2 className="text-dark text-700-cx  mt-2">{speaker.name}</h2>

              <h4 className="text-400 text-muted m-0">{speaker.title}</h4>
              <h4 className="text-dark text-900-cx  m-0 d-block px-0">{speaker.company}</h4>
            </Col>
          </Row>
        </Container>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>2022 SPEAKERS</b>
              </h1>
            </Col>
          </Row>
          <Row className="mb-4 justify-content-center">
            {chairmans.map((chairman) => (
              <Col lg={3} className="text-center">
                <a href="/" onClick={(e) => updateModal(e, chairman)}>
                  <img
                    src={require(`assets/speakers/${chairman.image}`)}
                    width="100%"
                    className="rounded-circle"
                    alt="..."
                    style={{
                      borderRadius: 0,
                      maxWidth: "450px",
                    }}
                  />
                </a>
                <div className="text-center">
                  <h2 className="text-dark text-700-cx  mt-2">{chairman.name}</h2>

                  <h4 className="text-400 text-muted m-0">{chairman.title}</h4>
                  <h4 className="text-dark text-900-cx  m-0 d-block px-0">{chairman.company}</h4>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="ml-auto mr-auto px-1" lg={3} md={6} key={index}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          {/* <Row className='justify-content-center'>
            <Col lg={3}>
              <Button
                href='/speakers'
                className='btn d-block py-2'
                color='primary'
                size='sm'
              >
                <p className='m-0 text-700' style={{ color: "#fff" }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row> */}
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close mr-2 mt-2"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
