import React from 'react';

import { Container, Row, Col, Card } from 'reactstrap';

import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';

const speakers = [
  {
    name: 'Vipin Kumar Ahuja',
    title: '',
    company: 'Associate Partner - Indirect Taxation',
    image: '01.jpg',
    companyImage: 'bmw.png',
    text: [],
  },
  {
    name: 'Deepak Variyam',
    title: '',
    company: 'Associate Partner - Indirect Taxation',
    image: '02.jpg',
    companyImage: 'bmw.png',
    text: [],
  },
  {
    name: 'Laila Aziki',
    title: '',
    company: 'Tax Agent',
    image: '03.jpg',
    companyImage: 'bmw.png',
    text: [],
  },
  {
    name: 'Lydia Wummel - Tyagi',
    title: '',
    company: 'Executive Director European Enterprise Team',
    image: '04.jpg',
    companyImage: 'bmw.png',
    text: [],
  },
];

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'p-10',
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3 rounded-0"
          style={{
            borderRadius: 0,
            boxShadow: 'none',
            // background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  alt="speaker"
                  width="100%"
                  className="rounded-0"
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center">
                  <p className="text-700  mt-2 mb-0 d-block px-0" style={{ fontSize: '14px' }}>
                    {speaker.name}
                  </p>
                  <p className="text-300 m-0" style={{ fontSize: '14px' }}>
                    {speaker.title}
                  </p>
                  <p className="text-400 m-0 d-block px-0" style={{ fontSize: '14px' }}>
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1 className="text-dark text-center section-title">
                <b>Our SPEAKERS</b>
              </h1>
              <h3 className="text-dark text-center">Changing Landscape of Tax Compliances & Audit frameworks in UAE</h3>
            </Col>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="ml-auto mr-auto px-1" lg={2} md={6} key={index}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={'mx-auto'}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SpeakerGrid;
