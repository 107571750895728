import React from "react";

function ProfilePageHeader({ title, bgColor }) {
  let pageHeader = React.createRef();

  const padding =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024
      ? "7rem 0 2rem 0rem"
      : "5rem 0 1rem 0rem";
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;

  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: padding,
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <h1
            className="text-center meta-title text-meta-g pt-3"
            style={{ fontSize: size ? "3rem" : "2rem" }}
          >
            <b>{title}</b>
          </h1>
        ) : (
          ""
        )}
      </div>
      {/* <Container fluid style={{ backgroundColor: "#1D1960" }}>
        <Row className="justify-content-center">
          {content.map((c, i) => (
            <Col lg={3} xs={6} key={i} className="py-3 text-center text-white">
              <h2 className="text-700 my-0">
                <i class={c.icon} aria-hidden="true"></i>
                {"  "}
                {c.value}
              </h2>
            </Col>
          ))}
        </Row>
      </Container> */}
    </>
  );
}

export default ProfilePageHeader;
