import React from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import CountUp from "react-countup";

function Event() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  // const Text = ({ grid, data }) => {
  //   return (
  //     <Col lg={grid} className={`${size ? "py-5" : "py-2"}`}>
  //       <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5}>
  //         <Container fluid>
  //           <Row
  //             className="text-center p-4"
  //             style={{ border: "3px solid #f04c21", height: size ? "220px" : "180px" }}
  //           >
  //             <Col xs={12} className="px-0">
  //               <h3 className={"m-0 text-700-cx text-dark"}>{data.pretitle}</h3>
  //             </Col>
  //             <Col xs={12}>
  //               <h1
  //                 className={"m-0 text-900-cx text-cx-g"}
  //                 style={{ color: "#000", fontSize: size ? "4rem" : "3rem" }}
  //               >
  //                 <b>
  //                   <small style={{ color: "#000" }}>
  //                     <b>{data.prefix}</b>
  //                   </small>

  //                   <CountUp end={data.value} duration={3} />

  //                   <small style={{ color: "#000" }}>
  //                     <b>{data.suffix}</b>
  //                   </small>
  //                 </b>
  //               </h1>
  //             </Col>
  //             <Col xs={12} className="px-0">
  //               <h3 className={"m-0 text-700-cx text-dark"}>{data.title}</h3>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </ScrollAnimation>
  //     </Col>
  //   );
  // };

  const Text = ({ grid, data }) => {
    return (
      <Col
        lg={grid}
        xs={12}
        className={`${size ? "py-2" : "py-2"}`}
        style={{ background: "rgba(0,0,0,0.3)" }}
      >
        <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5}>
          <Container fluid>
            <Row
              className="text-center p-3"
              style={{
                height: size && "100px",
              }}
            >
              <Col xs={12} className="">
                <h3 className={"m-0 text-900-cx text-white meta-title"}>{data.title}</h3>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </Col>
    );
  };
  return (
    <>
      <div
        style={{
          // backgroundColor: "#fff",
          padding: "0rem 0 6rem 0",
          backgroundImage: "url(" + require("assets/images/shutterstock_2127678866.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section pt-5"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-md-left">
            <Col lg={12}>
              <div className="">
                <h1
                  className=" section-title-cx pt-3 text-white meta-title text-left"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  <b>KEY THEMES </b>
                </h1>
                <h3 className="meta-title text-white">
                  THIS WOULD INCLUDE (BUT NOT BE LIMITED TO) THE FOLLOWING
                </h3>
              </div>
            </Col>
            {content.map((c) => (
              <Text key={c.title} grid={4} data={c} />
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "Welcome to Metaverse " },
  { title: "Genesis of Web 3.0 : How does Web3 relate to The Metaverse and its importance? " },
  { title: "Gateway to new era: Metaverse impact and its influence on our life-style" },
  { title: "NFTs, Crypto and Metaverse are interlinked, find out how and why? " },
  { title: "NFTs and The Metaverse: Mint your NFTs " },
  { title: "Buying virtual land in the Metaverse " },
  { title: "Digital twins — running a company in The Metaverse " },
  { title: "Dubai's role in Metaverse adoption" },
  { title: "Social media and The Metaverse " },
  { title: "The Open Metaverse " },
  { title: "Bridge to virtual journey: Onboarding GEN X to meet GEN Z " },
  { title: "Popular gadgets to navigate in Metaverse " },
];

export default Event;
