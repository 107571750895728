import React from 'react';
import {
  Col,
  Container,
  // Collapse
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import Button from 'reactstrap/lib/Button';

function Agenda() {
  const [activeTab, setActiveTab] = React.useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Container className="p-4 border-bottom">
        <Row>
          <Col lg={2} className="align-self-center">
            <h3 className="text-400 mb-0 text-white">
              <b>{d.time}</b>
            </h3>
          </Col>
          <Col lg={10} className="align-self-center text-white">
            <h2 className="text-400 m-0">{d.title}</h2>
            {d.text &&
              d.text.map((t) => (
                <h3 className="text-400 m-0 mt-1" dangerouslySetInnerHTML={{ __html: t }} />
              ))}
          </Col>
        </Row>
      </Container>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#201e45',
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={3}>
              <h1
                className="text-white section-title pt-3"
                style={{ borderTop: '5px solid #ec7026' }}
              >
                <b>AGENDA</b>
              </h1>
              <h2 className="text-700 text-white">(Gulf Standard Time)</h2>
            </Col>
            <Col md="9">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={content1} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
            {/* <Col lg={12} className="align-self-center text-center mt-3">
              <Button
                className="px-3 py-2 rounded-0"
                color="info"
                style={{ minWidth: '200px' }}
                href="/cx-evolve2022/brochure"
              >
                <p className="m-0 text-700" style={{ color: '#fff' }}>
                  Request Full Agenda
                </p>
              </Button>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content1 = [
  {
    time: '09.00',
    title: 'Welcome Coffee and Registration',
  },

  {
    time: '09:30',
    title: 'Welcome Note by Khaleej Times',
    text: [``],
  },

  {
    time: '09.40',
    title: `Opening Keynote: Leading from the front, the 'Services 360' policy of Dubai fundamentally changes the nature of government services`,
    text: [
      `
    <ul>
    <li>Design-thinking in the government sector </li>
<li>Customer Experience Policy, Setting priorities based on customers priorities, Design lab, Investing in human resources, Continuous Measurement and feedback. </li>
<li>Impact on Economy and larger Businesses in the region</li>

    </ul>
    `,
    ],
  },

  {
    time: '10.00',
    title: 'Keynote: Creating a strategy that empowers employees and engages the customer',
    text: [
      `
      <ul>
      <li>The total experience strategy: Encompassing the  multi-experience (MX), customer experience (CX), employee experience (EX) and user experience (UX)</li>
<li>Frictionless engagement of people and systems </li>
<li>Factors in Assessing Platforms</li>

      </ul>
      `,
    ],
  },
  {
    time: '10.20',
    title:
      'Panel Discussion: Integrating The experiences of Customers, Employees and Users through a single platform',
    text: [
      `<ul>
      <li>Insights from adaption to platform </li>
<li>Friction points pre and post adaption</li>
<li>What could be done better- Insights for the fence-sitters</li>

      </ul>`,
    ],
  },
  {
    time: '11.00',
    title: 'Networking Break',
    text: [``],
  },

  {
    time: '11.20',
    title: 'Case Study: Redesigning processes and measuring the Net Promoter Score (NPS) ',
    text: [
      `
      <ul>
      <li>Connecting company strategy and CX strategy </li>
<li>Objectives first- Working on transforming experiences</li>
<li>B2C versus B2B- Empathy road map Versus Process and function-driven</li>

      </ul>
      `,
    ],
  },
  {
    time: '11.40',
    title: 'Case study: Engaging the hyper-connected, Binge-watching Generation Z and millennials',
    text: [
      `
      <ul>
      
      <li>Understanding, setting, and Managing Expectations </li>
      <li>Omnichannel platform and customer engagement </li>
      <li>The metaverse investments and long-term outlook </li>
      
      </ul>
      `,
    ],
  },
  {
    time: '12.00',
    title: 'Delivering Experience as a Service',
    text: [
      `
    <ul>
    <li>Ownership of the full spectrum of service </li>
<li>Scalability of the CX ecosystem </li>
<li>Clear Fundamentals- Objectives, Vision, and the Mission</li>

    </ul>
    `,
    ],
  },
  {
    time: '12.20',
    title: 'Case Study: Creating a Design-led approach to service improvement',
    text: [
      `
    <ul>
    <li>Customer focus and impact on customer experience and operational excellence </li>
<li>Managing information flow to reduce primary queries </li>
<li>Increased NPS score </li>

    </ul>
    `,
    ],
  },
  {
    time: '13.00',
    title: 'Panel Discussion: Unlocking the Future of CX',
    text: [
      `
      <ul>
      <li>Fact-check: The actual status of the Digital First Strategy </li>
<li>Emotional Connect with Customers- What can be done more </li>
<li>AI, ML towards gaining foresight, personalization, and better decision making</li>
<li>Measuring the RoI of CX initiatives </li>

      </ul>
      `,
    ],
  },
  {
    time: '13.40',
    title: `Networking Lunch`,
    text: [``],
  },
  {
    time: '14.30',
    title: 'Retail Industry Focus: Tangible outcomes and improved CX experiences',
    text: [
      `
    <ul>
    <li>The never-ending need for speed- fast, faster, and even faster. </li>
<li>Big Data and Analytics for customer engagement </li>
<li>Multi-brand Vs Own Stores Vs Digital Experience: Delivering an authentic brand experience</li>

    </ul>
    `,
    ],
  },
  {
    time: '14.50',
    title: 'Banking Industry Focus: Security and Outsourcing Customer Management ',
    text: [
      `
      <ul>
      <li>Outsourcing versus in-house Process- Integrating the experience </li>
<li>New expectations and the digitally empowered customer</li>
<li>CX and product design: Front-to-back approach</li>

      </ul>
      `,
    ],
  },
  {
    time: '15.10',
    title: 'Airlines Industry Focus: Creating the new normal in the brave new world',
    text: [
      `
      <ul>
      <li>Leveraging and managing large-scale data </li>
<li>Hospitality is the key differentiator </li>
<li>Connected and seamless experience</li>

      </ul>
      `,
    ],
  },
  {
    time: '15.30',
    title: 'Panel Discussion: Creating Loyalty in a Fragile world',
    text: [
      `<ul>

      <li>Don't expect Loyalty, constantly strive for personalized engagement</li>
<li>Be honest, empathetic, and genuine as a brand </li>
<li>Loyalty through the prism of Big Data, Analytics </li>
<li>Engaging customers through AI, ML, and automation tech</li>
<li>Be Humanely available when needed </li>

      </ul>`,
    ],
  },
  {
    time: '16.10',
    title: 'Closing Remarks',
    text: [``],
  },
  {
    time: '16.15',
    title: 'End of Conference ',
    text: [``],
  },
];

export default Agenda;
