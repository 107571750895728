// reactstrap components
import React from "react";

import Register from "componentscloud4c/Register";
import TextGrid from "componentscloud4c/TextGrid";
import Agenda from "componentscloud4c/Agenda";
// import Sponsor from "components/Sponsor";
// import SpeakerGrid from "components/SpeakerCard";
import NavbarDiv from "componentscloud4c/Navbar";

function LandingPageHeader() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <NavbarDiv />
      <img
        src={require(`assets/images/${size ? "financebg.png" : "financebgmobile.png"}`)}
        width="100%"
        alt="bg"
        style={{ marginTop: size ? "0rem" : "2rem" }}
      />
      <TextGrid />
      <section id="agenda" />
      <Agenda />
      <section id="register" />
      <Register type="REGISTER" />
    </>
  );
}

export default LandingPageHeader;
