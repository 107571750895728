import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className='section'
        data-parallax={true}
      >
        <Container className='mt-5'>
          <Row>
            {recent.map((c) => (
              <Col lg={4}>
                <Card>
                  <CardImg
                    alt={c.title}
                    src={require(`assets/editions/${c.image}`)}
                    top
                    width='100%'
                  />
                  <CardBody>
                    <CardTitle tag='h2'>{c.title}</CardTitle>
                    <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                      {c.date}
                    </CardSubtitle>

                    <Button
                      color='primary'
                      size="sm"
                      href={`https://drive.google.com/uc?id=${c.link}&export=download`}
                    >
                      Download
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const recent = [
  {
    title: "Artelligence Forum 2022",
    date: "16th August 2022",
    description: "",
    link: "1uwPi4TMoMflvsKeMIO6fiAqre-uQQ53T",
    image: "artelligence.png",
  },

  {
    title: "Future of Insurance",
    date: "25th January 2021",
    image: "foic.png",
    link: "1AMAUXT6jfSQ4jXla2bRrL2IFKXSpJpQS",
  },

  {
    title: "Remote Workforce Summit",
    date: "15th March 2021",
    image: "remote.jpg",
    link: "1KfTFWPn_0bEyRuRoXaIo2FH97_7ZQSUC",
  },

  {
    title: "New Age Finance & Accounting Summit",
    date: "17th May 2021",
    image: "nafa.png",
    link: "1LgwbOg3RjJZ3sX4iRfU9_kqcG6kXM9bl",
  },

  {
    title: "Digital Health",
    date: "27th May 2021",
    image: "dhf.png",
    link: "1kt_LNLvnGPWhBCrCjwELPVpdmJVbet81",
  },

  {
    title: "DIGIBANK 2021",
    date: "26th October 2021",
    image: "digibank.png",
    link: "1qA21QBBqMXRvfP6t0JgocPvr7y5T5YCZ",
  },
  {
    title: "UniExpo",
    date: "7th & 8th October 2021",
    description: "",
    link: "1LD3BYrNhLDySR3vjG23U8BSN1TujGuQ3",
    image: "uniexpo.png",
  },

  {
    title: "IRES 2021",
    date: "10th & 11th Dec 2021",
    image: "ires.png",
    link: "1hGaqDNfAGYIvegaRc-N9WgMEz-NQQ7PE",
  },

  {
    title: "FutureSec 2021",
    date: "10th November 2021",
    image: "futuresec.png",
    link: "1zgFwrJ-aZqNn3koBv9sandvKxysYvvQT",
  },

  // {
  //   title: "India UAE Israel Summit",
  //   date: "19th October 2021",
  //   image: "uniexpo.png",
  // },

  // {
  //   title: "Karnataka Expo 2020 Summit",
  //   date: "17th October 2021",
  //   image: "uniexpo.png",
  // },
];

export default TextGrid;
