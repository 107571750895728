import React from "react";

import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";

import Events from "components/Events";
import TextGrid from "components/TextGrid";
import SpeakerGrid from "components/SpeakerCard";
function RegisterPage() {
  const common = {
    containment: "#bgndVideo",
    autoPlay: true,
    mute: true,
    startAt: 0,
    opacity: 1,
    loop: false,
    ratio: "4/3",
    addRaster: true,
  };
  let videos = [
    {
      videoURL: "vGM4NVAO30w",
      ...common,
    },
    {
      videoURL: "oacFcPy5xkE",
      ...common,
    },

    {
      videoURL: "u9k1FaMIYTs",
      ...common,
    },
  ];
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader
        text='Thought provoking discussions, and unique experiences from recognized global leaders.'
        videos={videos}
      />
      <TextGrid />
      <Events />
      <SpeakerGrid />

      <Footer />
    </>
  );
}

export default RegisterPage;
