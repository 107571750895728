// reactstrap componentsuaeIndia
import { Container, Col, Row, Button } from "reactstrap";

import React, { useEffect, useRef, useState } from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  // const [vantaEffect, setVantaEffect] = useState(0);
  // const myRef = useRef(null);

  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(
  //       window.VANTA.RINGS({
  //         el: myRef.current,
  //         mouseControls: true,
  //         touchControls: true,
  //         gyroControls: false,
  //         minHeight: 200.0,
  //         minWidth: 200.0,
  //         scale: 0.5,
  //         scaleMobile: 1.0,
  //         color: 0xfcfcfc,
  //         backgroundColor: `#000b2e`,
  //       })
  //     );
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy();
  //   };
  // }, [vantaEffect]);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "rgb(0,137,207)",
          backgroundImage:
            "url(" + require("assets/images/dubaiIndia.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className='page-header'
        // ref={myRef}
        data-parallax={true}
      >
        <div className='overlay-secondary'></div>
        <Container fluid style={{ paddingTop: size && "10vh" }}>
          <Row className=' justify-content-center'>
            <Col md={4} xs={12} className='text-center'>
              <img
                src={require(`assets/images/logo/logomain1.png`)}
                style={{ maxWidth: "350px" }}
                width='100%'
                alt='Finnovex'
              />
              {/* <Container>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className="text-center">
                    <img
                      src={require(`assets/images/logo/moe.png`)}
                      style={{ maxWidth: '150px' }}
                      width="100%"
                      alt="Finnovex"
                    />
                  </Col>
                  <Col lg={4} xs={6} className="text-center">
                    <img
                      src={require(`assets/images/logo/cg.jpg`)}
                      style={{ maxWidth: '150px' }}
                      width="100%"
                      alt="Finnovex"
                    />
                  </Col>
                </Row>
              </Container> */}

              <Container fluid>
                <Row>
                  <Col>
                    <h1
                      className='text-700 text-white text-center mt-1'
                      style={{ fontSize: size ? "1.25rem" : "1rem" }}
                    >
                      <i
                        className='fa fa-calendar'
                        style={{ color: "#04e1e5" }}
                        aria-hidden='true'
                      ></i>
                      <span className='ml-4'>MARCH, 2022 </span>
                      <i
                        className='fa fa-map-marker'
                        style={{ color: "#04e1e5", marginLeft: size && "20px" }}
                        aria-hidden='true'
                      ></i>
                      <span className='ml-2'>IN-PERSON, DUBAI</span>
                    </h1>
                  </Col>
                </Row>
              </Container>

              <br />
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col md={6} xs={12} className='mb-1' style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className='justify-content-center'>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className='px-3 py-2 rounded-0'
                      color='primary'
                      style={{ width: "99%", backgroundColor: "#051245" }}
                      href='/uae-india-investment-forum/register'
                    >
                      <p className='m-0 text-700' style={{ color: "#fff" }}>
                        REGISTER <br /> NOW
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className='px-3 py-2 rounded-0'
                      color='primary'
                      style={{ width: "99%", backgroundColor: "#051245" }}
                      href='/uae-india-investment-forum/brochure'
                    >
                      <p className='m-0 text-700' style={{ color: "#fff" }}>
                        DOWNLOAD <br />
                        BROCHURE
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
