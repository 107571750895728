import React from "react";
import {
  // Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={12}>
        <ScrollAnimation
          animateIn="fadeInDown"
          animateOnce={true}
          duration={0.4}
        >
          <Card style={{ backgroundColor: "#a000ff" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col lg={2} className="align-self-center">
                    <h2 className="text-900 text-white"> {d.time}</h2>
                  </Col>
                  <Col
                    lg={8}
                    className="align-self-center text-white border-left"
                  >
                    <h2 className="text-400">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundImage:
            "linear-gradient(39deg, rgba(51,0,100,1) 0%, rgba(46,2,86,1) 28%, rgba(138,9,162,1) 48%, rgba(198,47,152,1) 65%, rgba(255,143,80,1) 94%)",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center text-white section-title">
                  <b>AGENDA</b>
                </h1>
                <h2 className="text-700 text-center mb-4 text-white">
                  Accenture Exchange Opening Ceremony
                </h2>

                <Container>
                  <Row>
                    <AgendaCard data={content1} />
                  </Row>
                </Container>
              </Col>
              <Col md="12" className="mt-4">
                <h2 className="text-700 text-center mb-4 text-white">
                  Experience Expo 2020 with a special tour curated for you
                  (Optional)
                </h2>

                <Container>
                  <Row>
                    <AgendaCard data={content2} />

                    <Col lg={12}>
                      <h3 className="text-white text-400 text-center">
                        Do you have any questions about the event? Contact our
                        team by{" "}
                        <a
                          title="Expo2020Dubai@accenture.com"
                          href="mailto:Expo2020Dubai@accenture.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#a000fe" }}
                        >
                          clicking here.
                        </a>
                      </h3>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "04:30 pm",
    title: "Welcome to Accenture Exchange: Refreshments & Networking",
  },
  {
    time: "05.00pm",
    title: "Introduction to our journey today",
    description: `By Alexis Lecanuet, Regional Managing Director, Accenture Middle East.`,
  },
  {
    time: "05.15pm",
    title: "Expo 2020 Dubai, a success story of technology and human ingenuity",
    description: `Fireside Chat with Gerardo Canta, Senior Managing Director and Executive Sponsor for Accenture's partnership with Expo 2020 Dubai, Accenture Middle East
    & Expo 2020 Dubai leadership (TBC)`,
  },
  {
    time: "05.35pm",
    title:
      "Be a part of the magic: Celebrating Expo 2020 Dubai and its national and international importance",
    description: `By Xavier Anglada, Managing Director - Strategy & Consulting Lead, Accenture Middle East`,
  },
  {
    time: "05.50pm",
    title:
      "Creating the future together: A sneak peek into what is in store at the Exchange ",
    description: `By Xavier Anglada, Managing Director - Strategy & Consulting Lead, Accenture Middle East`,
  },
  {
    time: "06.05pm",
    title: "Wrap up & closing remarks at Accenture Exchange",
    description: `By Alexis Lecanuet, Regional Managing Director, Accenture Middle East.`,
  },
  {
    time: "06.15pm",
    title: "Cocktail & Networking",
  },
];
const content2 = [
  {
    time: "07.00pm",
    title:
      "Your preferred Expo pavilion visit: Opportunity, Sustainability or Mobility Pavilion",
  },
  {
    time: "07.45pm",
    title: "Your preferred country pavilion visit",
  },
  {
    time: "08.30pm",
    title: "Special entertainment show: Jalsat Nights @ Expo",
  },
];

export default Agenda;
